import styled from "@emotion/styled";

export const Body = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  grid-gap: 6rem;
  justify-content: center;
  padding-top: 3rem;
`;

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  display: flex;
  flex-direction: column;
  font-family: "Sky Text Regular", san-serif;
  font-size: 22pt;
  position: relative;
  z-index: 1;

  .icon {
    cursor: pointer;
    font-size: 4rem;
    margin: 0;
    user-select: none;
  }
`;

export const Footer = styled.div``;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem 4rem;

  p {
    margin: 0;
  }
`;
