import styled from "@emotion/styled";

import { uiHeight, uiWidth } from "../../../constants";

export const Button = styled.button`
  background-color: ${(p) =>
    p.disabled ? "lightgrey" : "rgba(9, 17, 83, 0.9)"};
  border: none;
  border-radius: 0.25rem;
  color: #eeeeee;
  font-family: "Sky Text Regular", san-serif;
  font-size: 16pt;
  padding: 1rem;

  &:hover {
    cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  }
`;

export const Canvas = styled.canvas<{ height: number; width: number }>`
  background-color: lightgrey;
  border: 1px solid lightgrey;
  border-radius: 0.25rem;
  height: ${(p) => p.height}px;
  pointer-events: none;
  transform: scaleX(-1);
  width: ${(p) => p.width}px;
`;

export const Container = styled.div`
  align-items: center;
  background-color: #eeeeee;
  display: flex;
  height: ${uiHeight}px;
  justify-content: center;
  grid-gap: 3rem;
  position: absolute;
  width: ${uiWidth}px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 3rem;
  margin-top: auto;
`;

export const Info = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-family: "Sky Text Regular", san-serif;
  font-size: 16pt;
  padding-left: 2rem;
  position: relative;

  &::before {
    color: inherit;
    content: "";
    font-family: "Sky Text Medium", san-serif;
    left: 0;
    top: 0;
    position: absolute;
  }
`;

export const List = styled.ul`
  margin: 0.5rem 0 0;
  padding-left: 0.5rem;
`;

export const Status = styled.div<{ complete?: boolean }>`
  color: rgba(0, 0, 0, 0.5);
  font-family: "Sky Text ${(p) => (p.complete ? "Regular" : "Light")}",
    san-serif;
  font-size: 16pt;
  padding-left: 2rem;
  position: relative;

  &::before {
    animation: ${(p) => (p.complete ? "none" : "spin 1s linear infinite")};
    color: ${(p) => (p.complete ? "#4BB543" : "inherit")};
    content: "${(p) => (p.complete ? "" : "")}";
    font-family: "Sky Text Medium", san-serif;
    left: 0;
    top: 0;
    position: absolute;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const StatusBox = styled.div<{ height: number; width: number }>`
  display: flex;
  flex-direction: column;
  height: ${(p) => p.height}px;
  grid-gap: 1rem;
  width: ${(p) => p.width - 100}px;
`;

export const Video = styled.video`
  opacity: 0;
  pointer-events: none;
  position: absolute;
`;
