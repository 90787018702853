import { Point } from "../../types";

export type ParticleMinimalData = {
  x: number;
  y: number;
  icon: string;
  id: string;
};

class ParticleModel {
  private _pt: Point = { x: 0, y: 0 };
  private _vel: Point;
  private readonly _start: Point;
  private readonly _startTime: number;
  private readonly _icon: string;
  private readonly _id: string;

  constructor(
    id: string,
    start: Point,
    vel: Point,
    startTime: number,
    icon: string
  ) {
    this._start = start;
    this._vel = vel;
    this._startTime = startTime;
    this._icon = icon;
    this._id = id;
  }

  update(time: number): boolean {
    const dt = time - this._startTime;
    this._pt = {
      x: this._start.x + dt * this._vel.x,
      y: this._start.y + dt * this._vel.y,
    };
    const minimumY = -100;
    return this._pt.y >= minimumY;
  }

  get position(): Point {
    return this._pt;
  }

  get icon(): string {
    return this._icon;
  }

  get data(): ParticleMinimalData {
    return {
      x: this._pt.x,
      y: this._pt.y,
      icon: this._icon,
      id: this._id,
    };
  }

  get id(): string {
    return this._id;
  }
}

export default ParticleModel;
