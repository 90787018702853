import { GridCommand, Sequence, SequenceStep } from "../../types";
import { Subject } from "rxjs";

class Sequencer {
  _sequences: Sequence[];
  _gridSubject: Subject<GridCommand>;

  constructor(sequences: Sequence[], subject: Subject<GridCommand>) {
    this._sequences = sequences;
    this._gridSubject = subject;
  }

  runSequence(label: string, callback?: () => void): void {
    const seq = this._sequences.find((s) => s.label === label);
    if (!seq) return;
    const [step, ...remainingSteps] = seq.steps;
    this.performStep(step, remainingSteps, callback);
  }

  performStep(
    step: SequenceStep | undefined,
    remainingSteps: SequenceStep[],
    callback?: () => void
  ) {
    if (step) {
      this.doStep(step);
      const [nextStep, ...lastSteps] = remainingSteps;
      setTimeout(() => {
        this.performStep(nextStep, lastSteps, callback);
      }, step.duration * 1000);
    } else {
      callback?.();
    }
  }

  doStep(step: SequenceStep) {
    const { command } = step;
    this._gridSubject.next(command);
  }
}

export default Sequencer;
