import React from "react";

import { uiHeight, uiWidth } from "../../../constants";
import { Container, Icon, Wrapper } from "./index.styles";

type Props = {
  gesture?: string;
  isPlaying?: boolean;
  showText?: boolean;
};

const Feedback: React.FC<Props> = ({ gesture, isPlaying, showText }) => {
  const gestureActions = {
    palm: isPlaying ? "Pause" : "Play",
    pinch: "Back",
    "thumb down": "Dislike",
    "thumb up": "Like",
  };
  const validGesture = gesture && Object.keys(gestureActions).includes(gesture);

  return (
    <Wrapper
      style={{
        opacity: validGesture ? 1 : 0,
        transition: validGesture ? "opacity 0.2s" : "none",
        height: uiHeight,
        width: uiWidth,
      }}
    >
      <Container>
        <Icon>
          <img src={`assets/icons/${gesture}.svg`} height="40px" width="40px" />
        </Icon>
        {showText && (gestureActions as any)[gesture ?? ""]}
      </Container>
    </Wrapper>
  );
};

export default Feedback;
