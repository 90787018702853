import { Sequence, VideoInfo } from "../types";

const netflixLogo = "./assets/Titles/logos/netflix_logo.png";
const skyOriginalLogo = "./assets/Titles/logos/sky_original_logo.png";
const skyFitnessLogo = "./assets/Titles/logos/Logo_Sky_fitness.png";

export const Content: VideoInfo[] = [
  {
    id: "chefsTable",
    videoSrc: "assets/movies/chefs_table/chefs_table.mpd",
    poster: {
      posterSrc: "assets/Posters/Poster_chefstable.jpeg",
      line1: "Sixth season",
      line2: "Documentary",
      titleArtSrc: "/assets/Titles/titleArt/Title Art Chefs Table.png",
      providerLogo: netflixLogo,
    },
  },
  {
    id: "gangsOfLondon",
    videoSrc: "assets/movies/gangs_squished/gangs_squished.mpd",
    poster: {
      posterSrc: "assets/Posters/Poster_gangs_of_london_small.jpeg",
      line1: "Because you like ZeroZeroZero",
      line2: "Gritty • Suspenseful • Exciting",
      titleArtSrc: "./assets/Titles/titleArt/gangs_of_london_title_art.png",
      providerLogo: skyOriginalLogo,
    },
  },
  {
    id: "parksAndRecreation",
    videoSrc: "assets/movies/parks_and_recs_ep_02/parks_and_recs_ep_02.mpd",
    poster: {
      posterSrc: "assets/Posters/Poster_Parks_and_Rec_stretch_small.jpeg",
      line1: "Next episode",
      line2: "Funny • Witty • Feelgood",
      titleArtSrc: "./assets/Titles/titleArt/parks_and_rec_title_art.png",
      providerLogo: netflixLogo,
    },
  },

  // Section 02

  {
    id: "strictlyComeDancing",
    videoSrc: "assets/movies/strictly/strictly.mpd",
    poster: {
      posterSrc: "assets/Posters/Strictly.jpg",
      line1: "Starts in 10 mins",
      line2: "Watch with friends",
      titleArtSrc: "assets/Titles/titleArt/Title_Strictly 1.png",
      providerLogo: "assets/Titles/logos/Channel_logo_bbc 1.png",
    },
  },
  {
    id: "littleBirds",
    videoSrc: "assets/movies/little_birds/little_birds.mpd",
    poster: {
      posterSrc: "assets/Posters/little_birds_1920.jpg",
      line1: "Continue Watching",
      line2: "S1 E2",
      titleArtSrc: "assets/Titles/titleArt/little_birds_title_art_2_.png",
      providerLogo: "assets/Titles/logos/Channel_logo_Sky_original 1.png",
    },
  },
  {
    id: "jumanji",
    videoSrc: "assets/movies/jumanji/jumanji.mpd",
    poster: {
      posterSrc: "assets/Posters/Jumanji.jpg",
      line1: "Because you like adventure",
      line2: "",
      titleArtSrc: "assets/Titles/titleArt/Jumanji logo.png",
      providerLogo: "assets/Titles/logos/Channel_logo_sky_cinema.png",
    },
  },

  // Section 03

  {
    id: "kaleighCohen",
    videoSrc: "assets/movies/kc_cycling/kc_cycling.mpd",
    poster: {
      posterSrc: "assets/Posters/Kaleigh Cohen 01.jpg",
      line1: "Ride to the rhythm",
      line2: "Starts in 40 mins",
      titleArtSrc: "assets/Titles/titleArt/kc_cycling.png",
      providerLogo: skyFitnessLogo,
    },
  },

  {
    id: "findYourCalm",
    videoSrc: "assets/movies/calm/calm.mpd",
    poster: {
      posterSrc: "assets/Posters/Calm01.jpg",
      line1: "New exercise",
      line2: "7 days of calming anxiety",
      titleArtSrc: "assets/Titles/titleArt/Title_Find_your_Calm.png",
      providerLogo: netflixLogo, //TODO update logo
    },
  },

  {
    id: "skyFitness",
    containsApps: true,
    videoSrc: "assets/movies/Seven_worlds_one_planet.mp4",
    poster: {
      posterSrc: "assets/Posters/Sky fitness 01.jpg",
      line1: "Explore the apps",
      line2: "",
      titleArtSrc: "assets/Titles/titleArt/logo_sky_fitness_big.png",
      providerLogo: skyFitnessLogo,
    },
  },

  // Extras

  {
    id: "TR",
    videoSrc: "assets/movies/cropped/croppedGangs.mp4",
    poster: {
      posterSrc: "assets/Posters/Poster_Tally_Rally_1920.jpeg",
      line1: "Ride to the rhythym",
      line2: "Starts in 40 mins",
      titleArtSrc: "assets/Titles/titleArt/Title_Tally_Rye_titles.png",
      providerLogo: skyFitnessLogo,
    },
  },

  {
    id: "TTD",
    videoSrc: "assets/movies/cropped/croppedGangs.mp4",
    poster: {
      posterSrc: "assets/Posters/third_day_1920.jpg",
      line1: "Because you liked the undoing",
      line2: "Mysterious • Dark • Ominous",
      titleArtSrc: "assets/Titles/titleArt/the_third_day_title_art.png",
      providerLogo: netflixLogo,
    },
  },
];

export const sequences: Sequence[] = [
  {
    label: "showLauncher",
    steps: [
      {
        command: {
          command: "no opp",
        },
        duration: 0.1,
      },
      {
        command: {
          command: "addMovie",
          videoInfo: Content[0],
        },
        duration: 1.2,
      },
      {
        command: {
          command: "addMovie",
          videoInfo: Content[1],
        },
        duration: 1.2,
      },
      {
        command: {
          command: "addMovie",
          videoInfo: Content[2],
        },
        duration: 1.2,
      },
    ],
  },
];
