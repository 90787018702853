import type { PaneMode, ReactionHotspot } from "./types";
import { Content } from "./data/sequences";
import { Rectangle } from "./types";

export const uiScale = 1;
export const uiWidth = 1920 * uiScale;
export const uiHeight = 1080 * uiScale;

export const panePreviewDuration = 3000;
export const paneCrossFadeTime = 500;

export const ReactionIcons = {
  pencil: {
    img: "assets/icons/noun-pencil-1778349-FFFFFF.svg",
    alt: "pencil",
  },
  heart: {
    img: "assets/icons/noun-classic-heart-outline-612687-FFFFFF.svg",
    alt: "heart",
  },
  thumbUp: {
    img: "assets/icons/noun-thumbs-up-996752-FFFFFF.svg",
    alt: "thumb up",
  },
  thumbDown: {
    img: "assets/icons/noun-thumbs-down-996748-FFFFFF.svg",
    alt: "thumb down",
  },
};

export const content: {
  [key in PaneMode]: { [key in typeof Content[number]["id"]]: string[] };
} = {
  BIKE_MODE: {
    kaleighCohen: ["kaleighCohen"],
    findYourCalm: ["findYourCalm"],
    skyFitness: ["skyFitness"],
  },
  KITCHEN_MODE: {
    chefsTable: ["chefsTable"],
    gangsOfLondon: ["gangsOfLondon"],
    parksAndRecreation: ["parksAndRecreation"],
  },
  WATCH_TOGETHER_MODE: {
    strictlyComeDancing: ["strictlyComeDancing"],
    littleBirds: ["littleBirds"],
    jumanji: ["jumanji"],
  },
};

export const backButtonRect: Rectangle = {
  x: 40,
  y: 40,
  width: 190,
  height: 90,
};

export const reactionButtonHotSpots: ReactionHotspot[] = [
  { rect: { x: 100, y: 200, width: 120, height: 120 }, label: "pencil" },
  { rect: { x: 100, y: 380, width: 120, height: 120 }, label: "thumbUp" },
  { rect: { x: 100, y: 520, width: 120, height: 120 }, label: "heart" },
  { rect: { x: 100, y: 660, width: 120, height: 120 }, label: "thumbDown" },
];
