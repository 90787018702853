import { filter, Observable, Subject, Subscription } from "rxjs";
import KeyListener from "./KeyListener";

import { Command } from "../types";

class Commander {
  _commandStream = new Subject<Command>();
  _keyStreamSubscription: null | Subscription = null;
  private static _instance: Commander | null = null;

  public static getInstance() {
    if (Commander._instance === null) {
      Commander._instance = new Commander();
    }
    return Commander._instance;
  }

  constructor() {
    this.initKeys();
  }

  sendCommand(command: Command): boolean {
    this._commandStream.next(command);
    return true;
  }

  initKeys() {
    const keyListener = KeyListener.getInstance();
    keyListener.init();
    this.attachKeyStream(keyListener.keyStream);
  }

  attachKeyStream(keyStream: Observable<KeyboardEvent>): void {
    if (this._keyStreamSubscription) this._keyStreamSubscription.unsubscribe();
    this._keyStreamSubscription = keyStream
      .pipe(filter((e) => e.type === "keydown"))
      .subscribe((event) => {
        this.interpretKeyDown(event);
      });
  }

  interpretKeyDown(event: KeyboardEvent) {
    switch (event.key.toUpperCase()) {
      case "W":
        this.sendCommand({
          type: "CHANGE_MODE",
          payload: "WATCH_TOGETHER_MODE",
        });
        break;
      case "K":
        this.sendCommand({ type: "CHANGE_MODE", payload: "KITCHEN_MODE" });
        break;
      case "B":
        this.sendCommand({ type: "CHANGE_MODE", payload: "BIKE_MODE" });
        break;
      case "E":
        this.sendCommand({ type: "SHOW EPISODES", payload: { paneColumn: 2 } });
        break;
      case "P":
        this.sendCommand({ type: "TOGGLE SHOW POINTER" });
        break;
      case "ARROWLEFT":
        this.sendCommand({ type: "BACK" });
        break;
      case "T":
        this.sendCommand({ type: "TOGGLE TILES" });
        break;
    }
  }

  destroy() {
    this._keyStreamSubscription?.unsubscribe();
  }

  get commandStream(): Observable<Command> {
    return this._commandStream;
  }
}

export default Commander;
