import { Container } from "./index.styles";
import { FC } from "react";
import { getFormattedDuration, todayAt, tweenTime } from "../../utils";

export type Props = {};

type CirclePathProps = {
  cx: number;
  cy: number;
  r: number;
};

const skyblue = "#34C6EC";

function donut(
  outerCx: number,
  outerCy: number,
  outerR: number,
  innerCx: number,
  innerCy: number,
  innerR: number
): string {
  return `M${outerCx} ${outerCy}m-${outerR},0a${outerR},${outerR},0 1,0 ${
    outerR * 2
  },0a ${outerR},${outerR} 0 1,0 -${
    outerR * 2
  },0z M${innerCx} ${innerCy}m-${innerR},0a${innerR},${innerR},0 0,1 ${
    innerR * 2
  },0a ${innerR},${innerR} 0 0,1 -${innerR * 2},0z`;
}

export const CirclePath: FC<CirclePathProps> = ({ cx, cy, r }) => (
  <path
    d={`M ${cx} ${cy} 
            m ${-r}, 0
            a ${r},${r} 0 1,0 ${r * 2},0
            a ${r},${r} 0 1,0 ${-(r * 2)},0
            z
        `}
  />
);

type TorusProps = {
  cx: number;
  cy: number;
  r1: number;
  r2: number;
};

export const TorusPath: FC<TorusProps> = ({ cx, cy, r1, r2 }) => (
  <path d={donut(cx, cy, r1, cx, cy, r2)} />
);

export type PlaybackUIProps = {
  duration: number;
  fraction: number;
  icon?: "pause" | "play";
};

export const PlaybackUI: FC<PlaybackUIProps> = ({ duration, fraction }) => {
  const widgetWidth = 1280;
  const xMargin = 325;
  const position = fraction * widgetWidth + xMargin;
  const t1 = todayAt(19, 0);
  const t2 = todayAt(19, 30);
  const t3 = tweenTime(fraction, t1, t2);

  return (
    <Container>
      <svg width={1960} height={250}>
        <rect
          fill={"rgba(255,255,255,0.4)"}
          x={xMargin}
          y={150 - 4}
          height={4}
          width={widgetWidth}
        />
        <text
          style={{
            fontFamily: "Sky Text Regular",
            fontSize: "22pt",
            fontWeight: 400,
          }}
          x={182}
          y={158}
          fill={"white"}
        >
          {getFormattedDuration(0)}
        </text>
        <text
          style={{
            fontFamily: "Sky Text Regular",
            fontSize: "22pt",
            fontWeight: 400,
          }}
          x={1630}
          y={158}
          fill={"white"}
        >
          {getFormattedDuration(duration)}
        </text>

        <g fill={skyblue} transform={`translate(${position},150)`}>
          <CirclePath cx={0} cy={0} r={18} />
          <text
            style={{
              fontFamily: "Sky Text Medium",
              fontSize: "20pt",
              fontWeight: 400,
            }}
            x={0}
            y={55}
            fill={skyblue}
            textAnchor={"middle"}
          >
            {getFormattedDuration(duration * fraction)}
          </text>
        </g>
      </svg>
    </Container>
  );
};
