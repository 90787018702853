import { Subject } from "rxjs";
import { drawConnectors, drawLandmarks } from "@mediapipe/drawing_utils";
import {
  Hands as MpHands,
  Options,
  Results,
  HAND_CONNECTIONS,
} from "@mediapipe/hands";

export type HandsOptions = Options & {
  onFrame?: () => void;
  onResults?: (results: Results) => void;
};
class Hands {
  private canvas: HTMLCanvasElement;
  private video: HTMLVideoElement;
  private ctx: CanvasRenderingContext2D | null;
  private options: HandsOptions;
  private hands: MpHands;

  public results = new Subject<Results>();
  public draw = true;

  constructor(
    canvas: HTMLCanvasElement,
    video: HTMLVideoElement,
    {
      minDetectionConfidence = 0.5,
      minTrackingConfidence = 0.5,
      ...rest
    }: HandsOptions = {}
  ) {
    this.canvas = canvas;
    this.video = video;
    this.options = {
      maxNumHands: 1,
      minDetectionConfidence,
      minTrackingConfidence,
      modelComplexity: 1,
      ...rest,
    };
    this.ctx = canvas.getContext("2d");
    this.hands = new MpHands({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/hands@0.4.1635986972/${file}`;
      },
    });
    this.hands.setOptions(this.options);
    this.hands.onResults(this.resultsListener.bind(this));
  }

  async destroy() {
    await this.hands.close();
  }

  async initialize() {
    await this.hands.initialize();
  }

  async send() {
    await this.hands.send({ image: this.video });
  }

  private resultsListener(results: Results) {
    this.options.onResults?.(results);
    this.results.next(results);
    if (this.draw) {
      this.drawHands(results);
    }
  }

  private drawHands(results: Results) {
    if (!this.ctx) {
      throw new Error(
        "Failed to retrieve context object from provided canvas."
      );
    }

    const { image, multiHandLandmarks } = results;

    this.ctx.save();
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.drawImage(image, 0, 0, this.canvas.width, this.canvas.height);

    for (const landmarks of multiHandLandmarks) {
      drawConnectors(this.ctx, landmarks, HAND_CONNECTIONS, {
        color: "#7DF9FF",
        lineWidth: 2,
      });
      drawLandmarks(this.ctx, landmarks, {
        color: "rgb(9, 17, 83)",
        radius: 1,
      });
    }

    this.ctx.restore();
  }
}

export default Hands;
