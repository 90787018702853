import { Container } from "./index.styles";
import { useEffect, FC, useState } from "react";
import PreloaderLogic, { PreloadInfo } from "./preloaderLogic";
import { preloadFiles } from "../../data/preloadFiles";

export type Props = { completeCallback: () => void };

const llamaPath = (
  <path
    d="M116.1 262.1c-6.9-18.5-5.4-37.5-4.2-56.7 1.1-17.7 5-34.8 8-52.9-12.4.7-24.9.6-36.9-5.6-11.9-6.1-16.3-16.2-19.2-27.9-.8-3.4 1.8-5.8 4.1-8 6.8-6.5 14.1-12.4 22.7-16.6 3.6-1.8 5.2-3.4 2.3-7.8-3.4-5.4 1-15.9 5.8-20.9 7.6-7.9 15.4-14.6 26.5-16.8 4.7-.9 5.1-6.1 6.3-9.7 2.2-6.8 5.1-7.9 11.3-4.1 4.1 2.5 7.1 9.5 12.2 6.7 3.1-1.7 3.7-8.1 5.5-12.4.6-1.4 1.4-2.8 2.1-4.2 5.8-12.3 9.2-12.8 19.2-2.8 7.1 7.1 12.4 16.2 11.4 26-1.4 12.2 6 20.1 11.2 28.7 5.8 9.7 7.2 19.2 7.8 30.2.5 9.8-1 20.1 2.3 29.7.8 2.3 1 4.4 0 6.3-5.1 10.2.1 20.7-.7 31-.4 5.2 2.3 5.8 6.4 3.8 4.5-2.1 9.5-3.1 13-7 1.6-1.9 3.9-3.2 6.4-3.4 14.7-1.3 29.1-5.5 43.6-7 23.2-2.5 46.6-2.2 69.7 3.4 7.3 1.8 14.1 5.5 22.6 4 4.3-.8 9.7 6.2 14.9 4.1s8.8.7 13.2 1.1c15.4 1.5 20.4 14.7 28.6 24.1 6.6 7.5 4 17.2 1.7 25.3-2.9 10.6-8.5 19.7-18.5 26.5-6.6 4.5-6.1 14.4-5.3 22.6 2.4 24.3 13.1 48.4 1.7 73.1-.2.3-.2.6-.2.9 4.7 22.9-6.1 46.1 1.4 69.1 1.5 4.5 1.6 9.4.5 14-3.7 13.9-6 28.2-6.9 42.5-.4 5.8-5.1 7.8-9.9 8.5-10.5 1.7-21 3.4-31.7 1.4-3.9-.8-7.7-1.2-9.1-5.3s1.3-7.2 3.9-10c1.6-1.8 4.1-2.9 4.7-5.6 2.6-16.7 3.6-33.7 3-50.6-.5 7.3-4.7 17.9-4.8 29.4 0 4.9.1 11-6.1 13.7-13.5 4.7-27.1 6.6-41 2.1-.6-3.2-4.1-5.8-1.9-9.6 16.3-19 12.9-40.8 8.9-62.7-.7-3.6-2.1-7.1-3.2-10.7-1.8-3.8-2.3-8.4-6.1-11.1l.1.1c-1.3-2.6-1-6-4.1-7.7-9.4-8.9-20-16.6-26.1-28.7-1-2-2.8-3.3-3.1-6.2-.5-5.3-3.9-8.1-9.6-6.9-1.4 2.7-1 5.4-.3 8.1-2.7 7.8.5 15.6 0 23.6-.9 15.7-1.8 30.4-9.8 44.8-4 7.3-1.6 18.1-5.2 26.8-1.2 2.5 1.2 5.9-1.9 7.8v-.1c-2.1 12 1.2 24.4-3.6 36.1-6.7 22.3-6.7 22.4-30.6 24.7-3 .3-6 2.4-9 .1-3.1-2.5-7-.2-10.3-1.8-1.4.2-2.7 0-3.1-1.7-1.5-2.7-.1-5.5-.2-8.2.2-4.7 4.2-5.8 7.4-7.6 2.5-1.9 4.1-4.2 3.8-7.5V448c-12.1 5.7-24.7 6.3-37.4 4.1-7.6-1.3-9.8-6.6-4.4-11.8 14.5-13.9 8.5-30.2 6.5-46.2-.3-2.4-2-4.9-1.8-7.2.7-9.3-2.6-17.3-7.7-24.7-.8-1.1-1.3-2.5-1.3-3.9-.2-13.9-2.8-27.8-.5-41.8.8-4.6-1.4-7.3-5.3-9.3-15.8-7.9-28.9-20.3-37.7-35.7-3.8-2.3.3-6.5-2-9.4z"
    style={{ fill: "white" }}
  />
);

export const Preloader: FC<Props> = ({ completeCallback }) => {
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    function handleProgress(info: PreloadInfo) {
      if (info.complete) completeCallback();
      setPercent(info.percent);
    }
    const preloader = new PreloaderLogic(preloadFiles, handleProgress);
    preloader.preload();
  }, []);

  return (
    <Container>
      <svg width={640} height={640}>
        <mask id="theMask">{llamaPath}</mask>
        <rect
          x={0}
          y={0}
          height={640}
          width={(640 * percent) / 100}
          fill={"black"}
          stroke={"none"}
          mask="url(#theMask)"
        />
      </svg>
      <p>Preloading prototype assets...</p>
    </Container>
  );
};
