import React from "react";
import { Observable } from "rxjs";

import { GestureData } from "../../../types";

import useGestureCaptureLogic, { GestureOptions } from "./index.logic";
import {
  Button,
  Canvas,
  Container,
  Footer,
  Info,
  List,
  Status,
  StatusBox,
  Video,
} from "./index.styles";

export type GestureCaptureProps = GestureOptions & {
  completeCallback?: () => void;
  draw: boolean;
};

const Capture = React.forwardRef<Observable<GestureData>, GestureCaptureProps>(
  ({ completeCallback, draw, hands, ...rest }, ref) => {
    const { cameraRef, canvasRef, devices, gestureStatus } =
      useGestureCaptureLogic(ref, {
        draw,
        hands,
      });

    const size = { height: 450, width: 600 };

    return (
      <>
        <Container style={{ display: draw ? "flex" : "none" }}>
          <Canvas ref={canvasRef} {...size} {...rest} />
          <StatusBox {...size}>
            {[
              "Gesture Initialization Started",
              "Media Stream Assigned",
              "MediaPipe Hands Initialized",
              "Video Data Loaded",
              "Initial Result Processed",
            ].map((status) => (
              <Status complete={gestureStatus.includes(status)} key={status}>
                {status}
              </Status>
            ))}
            <Footer>
              {devices.length > 0 && (
                <Info>
                  Devices Found:
                  <List>
                    {devices.map((device) => (
                      <li>{device}</li>
                    ))}
                  </List>
                </Info>
              )}
              <Button
                disabled={!gestureStatus.includes("Initial Result Processed")}
                onClick={completeCallback}
              >
                Continue
              </Button>
            </Footer>
          </StatusBox>
        </Container>
        <Video autoPlay muted playsInline ref={cameraRef} />
      </>
    );
  }
);

export default Capture;
