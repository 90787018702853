import jwt from "jsonwebtoken";

const OAUTH_URL = "https://oauth2.googleapis.com/token";

// Taken from https://developers.google.com/identity/protocols/oauth2/service-account#authorizingrequests
export const getAccessToken = async (
  clientEmail: string | undefined,
  privateKey: string | undefined,
  scope: string
) => {
  if (!privateKey) {
    throw new Error("No private key provided for Google API access token");
  }

  const now = Math.floor(Date.now() / 1000);
  const url = new URL(OAUTH_URL);
  url.search = new URLSearchParams({
    grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
    assertion: jwt.sign(
      {
        iss: clientEmail,
        scope,
        aud: OAUTH_URL,
        exp: now + 3600,
        iat: now,
      },
      privateKey,
      { algorithm: "RS256" }
    ),
  }).toString();

  const response = await fetch(url as any, {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
  if (response.ok) {
    const { access_token } = await response.json();
    return access_token;
  } else {
    throw new Error(
      `Got the following response from ${response.url}: ${response.status} - ${response.statusText}`
    );
  }
};
