import { EpisodeBlock } from "../types";

const Episodes: EpisodeBlock[] = [
  {
    id: "parksAndRecreation",
    episodes: [
      {
        titles: "assets/Titles/Episodes/Parks_EP01.png",
        poster: "assets/Posters/Episode_posters/Parks_EP01_Poster.png",
        content: "assets/movies/parks_and_recs_ep_02/parks_and_recs_ep_02.mpd"
      },
      {
        titles: "assets/Titles/Episodes/Parks_EP02.png",
        poster: "assets/Posters/Episode_posters/Parks_EP02_Poster.png",
        content: "assets/movies/parks_and_recs_ep_02/parks_and_recs_ep_02.mpd",
      },
      {
        titles: "assets/Titles/Episodes/Parks_EP03.png",
        poster: "assets/Posters/Episode_posters/Parks_EP03_Poster.jpeg",
        content: "assets/movies/parks_and_recs_ep_02/parks_and_recs_ep_02.mpd",
      },
    ],
  },
  {
    id: "skyFitness",
    episodes: [
      {
        titles: "assets/Titles/Episodes/TourFireAndIceEpisodeTitiles.png",
        poster: "assets/Posters/Episode_posters/Tour of fire and ice.jpg",
        content: "assets/movies/zwfit_parts/zwfit_parts.mpd",
        isApp: true,
      },
      {
        titles: "assets/Titles/Episodes/Tally Rye.png",
        poster: "assets/Posters/Episode_posters/Poster_Tally_Rally_1920.jpeg",
        content: "assets/movies/zwfit_parts/zwfit_parts.mpd",
        isApp: true,
      },
      {
        titles: "assets/Titles/Episodes/Tally Rye.png",
        poster: "assets/Posters/Episode_posters/Extra02.jpg",
        content: "assets/movies/zwfit_parts/zwfit_parts.mpd",
        isApp: true,
      },
    ],
  },
];

export default Episodes;
