import React, { useEffect, useRef, useState } from "react";
import { ToastProps } from "@ixd-group/llama-components";

import { getAccessToken } from "../../../../../services/googleOAuth";
import { useModule } from "../../..";
import Toast, { ToastHandle } from "../";

const CLIENT_EMAIL = process.env.REACT_APP_DIALOG_FLOW_CLIENT_EMAIL;
const PRIVATE_KEY = process.env.REACT_APP_DIALOG_FLOW_PRIVATE_KEY;
const PROJECT_NAME = process.env.REACT_APP_DIALOG_FLOW_PROJECT_NAME;

const debugLevel = 0;

const ToastController: React.FC = () => {
  const {
    stores: { gesture$ },
    services: { paneManager, socket, voiceListener },
  } = useModule();
  const toastRef = useRef<ToastHandle>(null);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState<ToastProps["status"]>("Idle");

  const cancelAnimations = () => {
    toastRef.current?.animations.forEach((animation) => {
      animation.cancel();
    });
  };

  useEffect(() => {
    socket.on("WAKE", () => {
      // @ts-ignore
      window.ReactNativeWebView?.postMessage("activate");
      if (voiceListener) {
        voiceListener.wake();
      }
    });
  }, [socket, voiceListener]);

  useEffect(() => {
    (window as any).voiceListener = voiceListener;

    if (voiceListener !== null) {
      voiceListener.on("unknown", () => {
        setStatus("Error");
        toastRef.current?.fadeOut(() => {
          setStatus("Idle");
        });
      });

      voiceListener.on("wakeword", () => {
        setMessage("Hello, how can I help?");
        setStatus("On");
      });

      voiceListener.on("result", ({ isFinal, listeningMode, phrase }) => {
        cancelAnimations();

        if (debugLevel > 0) {
          console.log(isFinal, phrase);
        }
        if (listeningMode === "command") {
          setStatus("Listening");
          setMessage(phrase);
        }
      });

      voiceListener.on("play-content", ({ contentId, episode }) => {
        if (contentId) {
          setStatus("Success");
          paneManager.launchContent(contentId, !!episode);
          if (episode) {
            setTimeout(() => {
              paneManager.playEpisode(`episode-${episode - 1}`);
            }, 3500);
          }
        } else if (episode) {
          setStatus(
            paneManager.playEpisode(`episode-${episode - 1}`)
              ? "Error"
              : "Success"
          );
        } else {
          setStatus("Error");
        }

        toastRef.current?.fadeOut(() => {
          setStatus("Idle");
        });
      });

      voiceListener.on("stop-content", () => {
        setStatus("Success");
        paneManager.findPane()?.pauseVideo();
        toastRef.current?.fadeOut(() => {
          setStatus("Idle");
        });
      });

      voiceListener.on("resume-content", () => {
        setStatus("Success");
        paneManager.findPane()?.resumeVideo();
        toastRef.current?.fadeOut(() => {
          setStatus("Idle");
        });
      });

      voiceListener.on("skip-forward", () => {
        setStatus("Success");
        paneManager.findPane()?.skipForward();
        toastRef.current?.fadeOut(() => {
          setStatus("Idle");
        });
      });

      voiceListener.on("skip-back", () => {
        setStatus("Success");
        paneManager.findPane()?.skipBack();
        toastRef.current?.fadeOut(() => {
          setStatus("Idle");
        });
      });

      voiceListener.on("go-to-episodes", ({ contentId }) => {
        setStatus("Success");
        paneManager.launchContent(contentId, true);
        toastRef.current?.fadeOut(() => {
          setStatus("Idle");
        });
      });

      voiceListener.on("go-back", () => {
        setStatus("Success");
        paneManager.handlePaneBack(null);
        toastRef.current?.fadeOut(() => {
          setStatus("Idle");
        });
      });

      const initDialogFlow = async () => {
        const accessToken = await getAccessToken(
          CLIENT_EMAIL,
          PRIVATE_KEY,
          "https://www.googleapis.com/auth/dialogflow"
        );
        voiceListener.options((options) => ({
          ...options,
          dialogFlow: {
            accessToken,
            projectName: PROJECT_NAME ?? "",
          },
        }));
      };
      initDialogFlow();

      return () => {
        // TODO Unregister voice events and switch off dialog flow
      };
    }
  }, [
    voiceListener,
    paneManager.paneData,
    paneManager.interaction$,
    gesture$,
    paneManager,
  ]);

  return (
    <Toast
      message={message}
      quoted={status !== "On" && message !== "Please try again."}
      ref={toastRef}
      status={status}
      style={{ pointerEvents: "none" }}
    />
  );
};

export default ToastController;
