import { FC, useEffect, useRef } from "react";
import styled from "@emotion/styled";

export const UnselectableImage = styled.img`
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

export type Props = {
  src: string;
  scale: number;
  alt: string;
};

export const ScaledImage: FC<Props> = ({ src, alt, scale }) => {
  // const [isReady,setIsReady] = useState(false)
  const imgRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      if (imgRef.current !== null) {
        const w = img.width;
        const h = img.height;
        imgRef.current.src = src;
        imgRef.current.width = w * scale;
        imgRef.current.height = h * scale;
      }
    };
    img.src = src;
  }, [scale, src]);
  return <UnselectableImage ref={imgRef} alt={alt} draggable="false" />;
};
