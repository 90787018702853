import { createModule } from "@ixd-group/react-utils";
import { createStores } from "./stores";
import { createServices } from "./services";
import { RootController } from "./components/Root/RootController";

export type Props = {};

export const [Launcher, useModule] = createModule<
  Props,
  typeof createStores,
  typeof createServices
>({
  createStores,
  createServices,
  root: RootController,
});
