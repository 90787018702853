import { interval, mergeMap, Observable, take } from "rxjs";
import { ParticleInfo } from "../../types";
import { Socket } from "socket.io-client";
import { FC, useEffect, useState } from "react";
import ParticleModel, { ParticleMinimalData } from "../Particle/ParticleModel";
import SimpleParticle from "../Particle/SimpleParticle";

export type Props = {
  particleRequest$: Observable<ParticleInfo>;
  socket: Socket;
  uuid: string;
};

const ParticleManager: FC<Props> = ({ particleRequest$, socket, uuid }) => {
  const [particles, setParticles] = useState<ParticleMinimalData[]>([]);
  const [foreignParticles, setForeignParticles] = useState<
    Map<string, ParticleMinimalData[]>
  >(new Map());

  useEffect(() => {
    let models: ParticleModel[] = [];
    let oldModels = models;
    let looping = true;
    let idCount = 1;

    function loop() {
      const now = new Date().getTime();
      const updatedModels: ParticleModel[] = [];
      models.forEach((m) => {
        if (m.update(now)) {
          updatedModels.push(m);
        }
      });
      models = updatedModels;
      const theParticles = models.map((m) => m.data);
      setParticles(theParticles);
      if (oldModels.length !== 0 && models.length !== 0) {
        socket.emit("BROADCAST", {
          event: "react_particles",
          payload: { particles: models.map((p) => p.data), uuid: uuid },
        });
      }
      oldModels = models;
      if (looping) {
        window.requestAnimationFrame(loop);
      }
    }

    window.requestAnimationFrame(loop);

    function spawn(kind: string) {
      const startX = Math.random() * 500 + 1920 / 2 - 250;
      const startY = Math.random() * 200 + 1080 - 200;
      const velocityX = (Math.random() * 20 - 10) / 32;
      const velocityY = (Math.random() * 10 - 20) / 24;
      const now = new Date().getTime();
      const id = "id" + idCount++;
      const model = new ParticleModel(
        id,
        { x: startX, y: startY },
        { x: velocityX, y: velocityY },
        now,
        kind
      );
      models.push(model);
    }

    const subscription = particleRequest$
      .pipe(
        // TODO Refactor to remove 2nd param resultSelector
        mergeMap(
          (val) => interval(200).pipe(take(5)),
          (particle, iVal, oIndex, iIndex) => ({
            particle,
            iVal,
            oIndex,
            iIndex,
          })
        )
      )
      .subscribe((data) => {
        spawn(data.particle.kind);
      });

    return () => {
      subscription.unsubscribe();
      looping = false;
      models = [];
    };
  }, [particleRequest$, socket, uuid]);

  useEffect(() => {
    function handleIncomingParticles(data: {
      uuid: string;
      particles: ParticleMinimalData[];
    }) {
      setForeignParticles((oldParticles) => {
        const newParticles = new Map(oldParticles);
        newParticles.set(data.uuid, data.particles);
        return newParticles;
      });
    }
    socket.on("react_particles", handleIncomingParticles);
    return () => {
      socket.off("react_particles", handleIncomingParticles);
    };
  }, [socket]);

  function foreignModelsToParticles(data: Map<string, ParticleMinimalData[]>) {
    const keys: string[] = Array.from(data.keys());
    const result = keys.map((key) => {
      const parts = data.get(key);
      const inner = parts
        ? parts.map((p) => <SimpleParticle model={p} key={p.id} />)
        : null;
      return <div key={key}>{inner}</div>;
    });
    return result;
  }

  return (
    <div style={{ width: "100%", height: "100%", position: "absolute" }}>
      {particles.map((p) => (
        <SimpleParticle model={p} key={p.id} />
      ))}
      {foreignModelsToParticles(foreignParticles)}
    </div>
  );
};

export default ParticleManager;
