import { readonlyAtom, atom, combine } from '@ixd-group/rx-utils';
import { BehaviorSubject, concat, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

class Clock {
    constructor() {
        [this.time$, this.setTime] = readonlyAtom(Date.now());
        setInterval(() => this.setTime(Date.now()), 1000);
    }
}

class GridStore {
    constructor(itemsPerRow) {
        this.index$ = atom(0);
        this.itemsPerRow = itemsPerRow;
        [this.items$, this.setItems] = readonlyAtom([]);
        this.rowIndex$ = this.index$.map(i => Math.floor(i / (this.itemsPerRow)));
        this.columnIndex$ = this.index$.map(i => i % this.itemsPerRow);
        this.focusedItem$ = combine([this.items$, this.index$], ([items, i]) => items[i]);
    }
    set(items, rowIndex = 0, columnIndex = 0) {
        if (this.totalNumItems()) {
            throw new Error(`You can only call "set" once on the GridStore`);
        }
        this.setItems(items);
        this.index$.set(rowIndex * this.itemsPerRow + columnIndex);
    }
    totalNumItems() {
        return this.items$.get().length;
    }
    indexIsValid(index) {
        return index >= 0 && index < this.totalNumItems();
    }
    nextRow() {
        let newIndex = this.index$.get() + this.itemsPerRow;
        const maxRows = Math.ceil(this.totalNumItems() / this.itemsPerRow);
        const nextRow = Math.floor(newIndex / this.itemsPerRow) + 1;
        if (nextRow <= maxRows) {
            if (this.indexIsValid(newIndex)) {
                this.index$.set(newIndex);
            }
            else {
                this.index$.set(this.totalNumItems() - 1);
            }
        }
    }
    prevRow() {
        const newIndex = this.index$.get() - this.itemsPerRow;
        if (this.indexIsValid(newIndex)) {
            this.index$.set(newIndex);
        }
    }
    nextItem() {
        const newIndex = this.index$.get() + 1;
        const maxColumns = (this.columnIndex$.get() + 1) < this.itemsPerRow;
        if (this.indexIsValid(newIndex) && maxColumns) {
            this.index$.set(newIndex);
        }
    }
    prevItem() {
        const index = this.index$.get();
        const newIndex = index > 0 ? index - 1 : index;
        if (newIndex >= 0 && this.columnIndex$.get() > 0) {
            this.index$.set(newIndex);
        }
    }
}

class HeroStore {
    constructor() {
        this.hero$ = atom(undefined);
    }
    set(hero) {
        this.hero = hero;
        this.heroOverride = undefined;
        this.update();
    }
    override(partialHero) {
        this.heroOverride = partialHero;
        this.update();
    }
    removeOverride() {
        this.heroOverride = undefined;
        this.update();
    }
    update() {
        const hero = this.hero
            ? this.heroOverride
                ? Object.assign(Object.assign({}, this.hero), this.heroOverride) : this.hero
            : undefined;
        this.hero$.set(hero);
    }
}

class HistoryStore {
    constructor() {
        this.bookmarks$ = atom([]);
        this.last$ = this.bookmarks$.map(bookmarks => bookmarks[bookmarks.length - 1]);
    }
    clear() {
        this.bookmarks$.set([]);
    }
    pop() {
        this.bookmarks$.update(bookmarks => bookmarks.slice(0, bookmarks.length - 1));
    }
    push(bookmark) {
        this.bookmarks$.update(bookmarks => [...bookmarks, bookmark]);
    }
}

class ItemSelector {
    constructor(length, index = 0) {
        this.length = length;
        [this.index$, this._setIndex] = readonlyAtom(index);
    }
    next() {
        this.setIndex(this.index$.get() + 1);
    }
    prev() {
        this.setIndex(this.index$.get() - 1);
    }
    setIndex(index) {
        if (0 <= index && index < this.length) {
            this._setIndex(index);
        }
    }
    setLength(length) {
        this.length = length;
        const index = this.index$.get();
        if (index >= length) {
            this._setIndex(length === 0 ? 0 : length - 1);
        }
    }
}

class InfiniteItemSelector extends ItemSelector {
    constructor(length, index = 0) {
        super(length, index);
        this.normalisedIndex$ = this.index$.map(index => ((index % this.length) + this.length) % this.length);
    }
    next() {
        const index = this.index$.get();
        this._setIndex(index + 1);
    }
    prev() {
        const index = this.index$.get();
        this._setIndex(index - 1);
    }
    setIndex(index) {
        this._setIndex(index);
    }
}

class RailsStore {
    constructor() {
        //////////////////////////////
        this.railSelector = new ItemSelector(0);
        this.itemSelectors = [];
        this.initialIndices$ = new BehaviorSubject([0, 0]);
        [this.rails$, this.setRails] = readonlyAtom([]);
        this.focusedItem$ = combine([
            this.rails$,
            concat(this.initialIndices$, this.railSelector.index$.pipe(switchMap((i) => {
                const selector = this.itemSelectors[i];
                if (selector) {
                    const index$ = "normalisedIndex$" in selector ? selector.normalisedIndex$ : selector.index$;
                    return index$.pipe(map((j) => [i, j]));
                }
                return of([0, 0]);
            }))),
        ], ([rails, [i, j]]) => { var _a; return (_a = rails[i]) === null || _a === void 0 ? void 0 : _a.items[j]; });
    }
    get railIndex$() {
        return this.railSelector.index$;
    }
    setRailIndex(i) {
        this.railSelector.setIndex(i);
    }
    nextRail() {
        this.railSelector.next();
    }
    prevRail() {
        this.railSelector.prev();
    }
    itemIndex$(railIndex) {
        return this.itemSelector(railIndex).index$;
    }
    itemIndices() {
        return this.itemSelectors.reduce((object, itemSelector, index) => (Object.assign(Object.assign({}, object), { [index]: itemSelector.index$.get() })), {});
    }
    setItemIndex(railIndex, itemIndex) {
        this.itemSelector(railIndex).setIndex(itemIndex);
    }
    nextItem(railIndex) {
        this.itemSelector(railIndex).next();
    }
    prevItem(railIndex) {
        this.itemSelector(railIndex).prev();
    }
    set(rails, { itemIndices = {}, railIndex = 0 } = {}) {
        if (this.itemSelectors.length) {
            throw new Error(`You can only call "set" once on the RailsStore`);
        }
        this.itemSelectors = rails.map(({ items, isInfinite }, index) => {
            return isInfinite
                ? new InfiniteItemSelector(items.length, itemIndices[index])
                : new ItemSelector(items.length, itemIndices[index]);
        });
        this.railSelector.setLength(rails.length);
        this.railSelector.setIndex(railIndex);
        this.setRails(rails);
        this.initialIndices$.complete(); // so that focusedItem$ will start coming from itemSelectors etc.;
    }
    itemSelector(index) {
        const selector = this.itemSelectors[index];
        if (!selector) {
            throw new Error(`You tried to access rail with index ${index} but there are only ${this.rails$.get().length} rails`);
        }
        return selector;
    }
}

export { Clock, GridStore, HeroStore, HistoryStore, InfiniteItemSelector, ItemSelector, RailsStore };
