import { io } from "socket.io-client";
import { VoiceListener } from "@ixd-group/common-services";
import { ReadonlyAtom } from "@ixd-group/rx-utils";

import PaneManager from "./services/PaneManager";
import type { Props } from ".";
import Commander from "./services/Commander";
import CommandInterpreter from "./services/CommandInterpreter";
import { v4 } from "uuid";

const socketServer = process.env.REACT_APP_SOCKET_SERVER;

export const createServices = (moduleProps$: ReadonlyAtom<Props>) => {
  let ss: string = "";
  if (socketServer !== undefined) {
    ss = socketServer;
  } else {
    console.error(
      "Socket server is undefined (see .env file REACT_APP_SOCKET_SERVER=https://blah.com)"
    );
  }
  const socket = io(ss);
  const uuid = v4();

  let voiceListener = null;
  if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
    voiceListener = new VoiceListener<{
      "go-back": {};
      "go-to-content": { contentId: string };
      "go-to-episodes": { contentId: string };
      "play-content": { contentId?: string; episode?: number };
      "stop-content": {};
      "resume-content": {};
      "skip-forward": {};
      "skip-back": {};
    }>(
      {},
      {
        recognition: {
          continuous: false,
          interimResults: true,
          language: "en-GB",
        },
        wakeword: "hello sky",
        wakewordPerRequest: true,
      }
    );
  }

  const paneManager = new PaneManager();
  const commander = Commander.getInstance();
  const commandInterpreter = new CommandInterpreter(paneManager, voiceListener);
  commander.commandStream.subscribe((command) => {
    commandInterpreter.dispatchCommand(command);
  });

  socket.onAny((type: any, payload: any) => {
    commandInterpreter.dispatchCommand({ type, payload });
  });

  return {
    commandInterpreter,
    paneManager,
    socket,
    voiceListener,
    uuid,
  };
};
