import React, { FC, useEffect, useMemo, useState } from "react";
import { ImageHolder, Layer } from "../Pane/index.styles";
import {
  EpisodeData,
  PaneCommand,
  PaneInteraction,
  VideoCommand,
} from "../../types";
import styled from "@emotion/styled";
import { ScaledImage } from "../../../../shared-components/ScaledImage";
import { motion, useAnimation } from "framer-motion";
import { BehaviorSubject, Subject } from "rxjs";
import VideoLayer from "../VideoLayer";

export const TitleWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const InnerWrapper = styled.div`
  padding: 40px;
`;

type Props = EpisodeData & {
  interaction$: Subject<PaneInteraction>;
  command$: BehaviorSubject<PaneCommand>;
  videoSrc: string;
};

export const Episode: FC<Props> = ({
  dimensions,
  poster,
  titles,
  id,
  interaction$,
  command$,
  videoSrc,
  isApp,
}) => {
  const instruction$ = useMemo(() => new Subject<VideoCommand>(), []);
  const [videoVisible, setVideoVisible] = useState<boolean>(false);
  const [controlsVisible, setControlsVisible] = useState<boolean>(true);
  const videoControls = useAnimation();
  const posterControls = useAnimation();
  const { top, width, height, left } = dimensions;

  function handleVideoFadeComplete() {
    setVideoVisible(false);
  }

  useEffect(() => {
    function showVideo() {
      setVideoVisible(true);
      setControlsVisible(false);
      videoControls.start({ opacity: 1 });
      posterControls.start({ opacity: 0 });
      instruction$.next({ action: "play" });
    }

    function showPoster() {
      videoControls.start(
        { opacity: 0 },
        { onComplete: handleVideoFadeComplete }
      );
      posterControls.start({ opacity: 1 });
      instruction$.next({ action: "pause" });
    }

    const subscription = command$.subscribe((command) => {
      switch (command.command) {
        case "playVideo":
          showVideo();
          break;
        case "pauseVideo":
          instruction$.next({ action: "pause" });
          setControlsVisible(true && !isApp);
          break;
        case "showPoster":
          showPoster();
          break;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [command$, posterControls, videoControls, instruction$, isApp]);

  function handleClick() {
    interaction$.next({
      id,
      action: "episodeClick",
    });
  }

  const posterElements = (
    <motion.div
      style={{ width: "100%", height: "100%", position: "absolute" }}
      animate={posterControls}
    >
      <Layer>
        <ImageHolder src={poster} />
      </Layer>
      <TitleWrapper>
        <motion.div
          style={{ padding: "40px" }}
          animate={{ opacity: [0, 1], transition: { duration: 3 } }}
        >
          <ScaledImage src={titles} scale={0.4} alt={"episode titles"} />
        </motion.div>
      </TitleWrapper>
    </motion.div>
  );

  const videoElements = (
    <motion.div
      style={{ width: "100%", height: "100%", position: "absolute" }}
      animate={videoControls}
    >
      <VideoLayer
        command$={command$}
        interaction$={interaction$}
        instruction$={instruction$}
        showControls={controlsVisible}
        src={videoSrc}
        visible={videoVisible}
      />
    </motion.div>
  );

  return (
    <div
      style={{
        top,
        width,
        left,
        height,
        position: "absolute",
        overflow: "hidden",
      }}
      onClick={handleClick}
    >
      {videoElements}
      {posterElements}
    </div>
  );
};
