import { FC, useEffect, useState } from "react";
import { Container, ExtraContainer, Spacer } from "./index.styles";
import MovingThing from "../MovingThing";
import { createAnimationStream } from "../../utils";
import { Observable } from "rxjs";
import { ControlsAnimationDefinition } from "framer-motion/types/animation/types";
import { ScaledImage } from "../../../../shared-components/ScaledImage";

export type TitleCommand =
  | { command: "shut" }
  | { command: "open" }
  | { command: "hide" }
  | { command: "reveal" }
  | { command: "revealLeft" };

export type TitlePosition = "left" | "center";

export type Props = {
  line1: string;
  line2: string;
  titleArtSrc: string;
  providerLogo: string;
  scale?: number;
  command$: Observable<TitleCommand>;
};

function createInitialAnimationStreams(): Observable<ControlsAnimationDefinition>[] {
  const streams = [];
  for (let i = 0; i < 4; i++) {
    const animationStream$ = createAnimationStream();
    animationStream$.next({
      y: [600, 0],
      opacity: [0, 1],
      transition: { delay: i * 0.1 },
    });
    streams.push(animationStream$);
  }
  return streams;
}

function createAnimationStreams(
  targetY: number,
  targetOpacity: number,
  callback?: () => void
): Observable<ControlsAnimationDefinition>[] {
  const streams = [];
  for (let i = 0; i < 4; i++) {
    const animationStream$ = createAnimationStream();
    animationStream$.next({
      y: targetY,
      opacity: targetOpacity,
      transition: {
        delay: i * 0.1,
        onComplete: i === 3 ? callback : undefined,
      },
    });
    streams.push(animationStream$);
  }
  return streams;
}

const baseStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  margin: "4px",
  userSelect: "none",
};

export const ContentTitle: FC<Props> = ({
  line1,
  line2,
  titleArtSrc,
  providerLogo,
  scale,
  command$,
}) => {
  const [position, setPosition] = useState<TitlePosition>("center");
  const [streams, setStreams] = useState<
    Observable<ControlsAnimationDefinition>[]
  >(createInitialAnimationStreams());

  useEffect(() => {
    function shutStep1() {
      setStreams(createAnimationStreams(600, 0, shutStep2));
    }

    function shutStep2() {
      setPosition("center");
      setStreams(createAnimationStreams(0, 1));
    }

    function openStep1() {
      setStreams(createAnimationStreams(600, 0, openStep2));
    }

    function openStep2() {
      setPosition("left");
      setStreams(createAnimationStreams(0, 1));
    }

    const subscription = command$.subscribe((message) => {
      switch (message.command) {
        case "shut":
          shutStep1();
          break;
        case "open":
          openStep1();
          break;
        case "hide":
          setStreams(createAnimationStreams(600, 0));
          break;
        case "reveal":
          setStreams(createAnimationStreams(0, 1));
          break;
        case "revealLeft":
          setPosition("left");
          setStreams(createAnimationStreams(0, 1));
          break;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [command$]);

  const actualScale = scale === undefined ? 0.5 : 0.5 * scale;

  return (
    <ExtraContainer>
      <Container>
        <MovingThing
          props={{
            style: {
              fontSize: `${actualScale * 56}px`,
              paddingBottom: `${actualScale * 120}px`,
              paddingTop: `${actualScale * 12}px`,
              ...baseStyle,
            },
          }}
          animation$={streams[0]}
        >
          {line2}
        </MovingThing>
        <MovingThing
          props={{
            style: {
              fontSize: `${actualScale * 64}px`,
              ...baseStyle,
            },
          }}
          animation$={streams[1]}
        >
          {line1}
        </MovingThing>
        <MovingThing
          props={{
            style: {
              paddingTop: `${actualScale * 20}px`,
              ...baseStyle,
            },
            padding: "100px",
          }}
          animation$={streams[2]}
        >
          <ScaledImage
            src={titleArtSrc}
            alt={"title art"}
            scale={actualScale}
          />
        </MovingThing>
        <MovingThing
          props={{
            style: baseStyle,
          }}
          animation$={streams[3]}
        >
          <ScaledImage src={providerLogo} alt={"logo"} scale={actualScale} />
        </MovingThing>
      </Container>
      {position === "left" ? (
        <>
          <Spacer />
          <Spacer />
        </>
      ) : null}
    </ExtraContainer>
  );
};

export default ContentTitle;
