import React, { useImperativeHandle, useRef } from "react";
import { Toast as LlamaToast, ToastProps } from "@ixd-group/llama-components";
import { uiHeight, uiWidth } from "../../constants";

export type ToastHandle = {
  animations: Animation[];
  fadeOut: (onfinish?: Animation["onfinish"]) => void;
};

const Toast = React.forwardRef<ToastHandle, ToastProps>((props, ref) => {
  const animationsRef = useRef<Animation[]>([]);
  const toastRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    animations: animationsRef.current,
    fadeOut: (onfinish = null) => {
      if (toastRef.current) {
        const animation = toastRef.current.animate(
          [{ opacity: 1 }, { opacity: 0 }],
          {
            delay: 3000,
            duration: 1000,
          }
        );
        animation.onfinish = onfinish;
        animationsRef.current.push(animation);
      }
    },
  }));

  return (
    <LlamaToast
      {...props}
      ref={toastRef}
      style={{
        height: uiHeight,
        width: uiWidth,
        ...props.style,
      }}
    />
  );
});

export default Toast;
