import React, { useEffect, useMemo, useState } from "react";
import { Subject } from "rxjs";
import { Header } from "@ixd-group/llama-components";

import { useModule } from "../../";
import { Autosize } from "../../../../shared-components/Autosize";
import Gesture from "../Gesture";
import ToastController from "../Toast/ToastController";
import type { GridCommand } from "../../types";
import { sequences } from "../../data/sequences";
import Sequencer from "../Grid/Sequencer";
import { Grid } from "../Grid";
import { uiHeight, uiWidth } from "../../constants";

import { Container, Identifier } from "./index.styles";
import { Preloader } from "../Preloader";
import { useRxState } from "@ixd-group/react-utils";

export const dimensions = {
  width: uiWidth,
  height: uiHeight,
  x: 0,
  y: 0,
};

export const Root: React.FC = () => {
  const [theTime, setTheTime] = useState(0);
  const [preloadComplete, setPreloadComplete] = useState(false);
  const [gestureInitializeComplete, setGestureInitializeComplete] =
    useState(false);
  const [initialSequenceComplete, setInitialSequenceComplete] = useState(false);
  const [socketId, setSocketId] = useState("");

  const {
    services: { commandInterpreter, voiceListener, paneManager, socket },
    stores: { headerVisible$ },
  } = useModule();
  const command$ = useMemo(() => new Subject<GridCommand>(), []);
  const headerVisible = useRxState(headerVisible$);

  useEffect(() => {
    if (gestureInitializeComplete) {
      const seq = new Sequencer(sequences, command$);
      seq.runSequence("showLauncher", () => {
        // @ts-ignore
        if (voiceListener && !window.ReactNativeWebView) {
          voiceListener.start();
        }
        setInitialSequenceComplete(true);
      });
    }
  }, [command$, voiceListener, gestureInitializeComplete]);

  useEffect(() => {
    (window as any).commandInterpreter = commandInterpreter;
  }, [commandInterpreter]);

  useEffect(() => {
    let loop = true;
    function animate() {
      if (loop) {
        const now = new Date().getTime();
        setTheTime(now);
      }
    }
    window.requestAnimationFrame(animate);
    return () => {
      loop = false;
    };
  }, []);

  useEffect(() => {
    socket.onAny((type) => {
      if (type === "IDENTIFY") {
        setSocketId(socket.id);
        setTimeout(() => {
          setSocketId("");
        }, 5000);
      }
    });
  }, [socket]);

  function handleResize(scale: number) {
    paneManager.scale = scale;
  }

  function handlePreloadComplete() {
    setPreloadComplete(true);
    console.log("Preload Complete");
  }

  function handleGestureInitializeComplete() {
    setGestureInitializeComplete(true);
  }

  return (
    <Container>
      <Autosize resize={true} dimensions={dimensions} onResize={handleResize}>
        {!preloadComplete ? (
          <Preloader completeCallback={handlePreloadComplete} />
        ) : (
          <Gesture
            completeCallback={handleGestureInitializeComplete}
            draw={!gestureInitializeComplete}
          />
        )}
        {gestureInitializeComplete && (
          <>
            {socketId && (
              <Identifier>
                #{socketId.substring(socketId.length - 3)}
              </Identifier>
            )}
            {headerVisible && (
              <div className="header">
                <Header
                  style={{
                    width: uiWidth,
                    pointerEvents: "none",
                  }}
                  time={theTime}
                />
              </div>
            )}
            <Grid command$={command$} />
            {voiceListener === null ? null : <ToastController />}
          </>
        )}
      </Autosize>
    </Container>
  );
};
