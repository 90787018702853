export const preloadFiles = [
  "assets/Posters/Background_blue.jpg",
  "assets/Posters/Calm01.jpg",
  "assets/Posters/Episode_posters/Extra02.jpg",
  "assets/Posters/Episode_posters/Parks_EP01_Poster.png",
  "assets/Posters/Episode_posters/Parks_EP02_Poster.png",
  "assets/Posters/Episode_posters/Parks_EP03_Poster.jpeg",
  "assets/Posters/Episode_posters/Poster_Tally_Rally_1920.jpeg",
  "assets/Posters/Episode_posters/Tour of fire and ice.jpg",
  "assets/Posters/Jumanji.jpg",
  "assets/Posters/Jumanji.png",
  "assets/Posters/Kaleigh Cohen 01.jpg",
  "assets/Posters/Poster_Calm_small.jpg",
  "assets/Posters/Poster_Parks_and_Rec_stretch_small.jpeg",
  "assets/Posters/Poster_chefstable.jpeg",
  "assets/Posters/Poster_gangs_of_london_small.jpeg",
  "assets/Posters/Poster_promising_young_woman_small.jpeg",
  "assets/Posters/Poster_strictly.jpeg",
  "assets/Posters/Sky fitness 01.jpg",
  "assets/Posters/Strictly.jpg",
  "assets/Posters/Strictly.png",
  "assets/Posters/little_birds_1920.jpg",
  "assets/Posters/originals/Background_blue.png",
  "assets/Posters/originals/Poster_Calm.png",
  "assets/Posters/originals/Poster_Parks_and_Rec.png",
  "assets/Posters/originals/Poster_Tally_Rally_1920.png",
  "assets/Posters/originals/Poster_gangs_of_london.png",
  "assets/Posters/originals/Poster_promising_young_woman.png",
  "assets/Posters/originals/Poster_strictly.jpeg",
  "assets/Posters/originals/Poster_strictly.png",
  "assets/Posters/third_day_1920.jpg",
  "assets/Titles/Episodes/Parks_EP01.png",
  "assets/Titles/Episodes/Parks_EP02.png",
  "assets/Titles/Episodes/Parks_EP03.png",
  "assets/Titles/Episodes/Tally Rye.png",
  "assets/Titles/Episodes/Title_Parks&Rec_Seasons.png",
  "assets/Titles/Episodes/TourFireAndIceEpisodeTitiles.png",
  "assets/Titles/Main/Title_Parks&Rec.png",
  "assets/Titles/Main/Title_gangs_of_london.png",
  "assets/Titles/Main/Title_promising_young_woman.png",
  "assets/Titles/logos/Channel_logo_Sky_original 1.png",
  "assets/Titles/logos/Channel_logo_bbc 1.png",
  "assets/Titles/logos/Channel_logo_sky_cinema.png",
  "assets/Titles/logos/Logo_Sky_fitness.png",
  "assets/Titles/logos/netflix_logo.png",
  "assets/Titles/logos/sky_original_logo.png",
  "assets/Titles/titleArt/Jumanji logo.png",
  "assets/Titles/titleArt/Logo_Calm.png",
  "assets/Titles/titleArt/Logo_Tally_Rye.png",
  "assets/Titles/titleArt/StrictlyTitleArt.png",
  "assets/Titles/titleArt/Title Art Chefs Table.png",
  "assets/Titles/titleArt/Title_Find_your_Calm.png",
  "assets/Titles/titleArt/Title_Little_Birds 1.png",
  "assets/Titles/titleArt/Title_Strictly 1.png",
  "assets/Titles/titleArt/Title_Tally_Rye.png",
  "assets/Titles/titleArt/Title_Tally_Rye_titles.png",
  "assets/Titles/titleArt/Tour of fire and ice.png",
  "assets/Titles/titleArt/gangs_of_london_title_art.png",
  "assets/Titles/titleArt/kc_cycling.png",
  "assets/Titles/titleArt/little_birds_title_art.png",
  "assets/Titles/titleArt/little_birds_title_art_2_.png",
  "assets/Titles/titleArt/logo_sky_fitness_big.png",
  "assets/Titles/titleArt/parks_and_rec_title_art.png",
  "assets/Titles/titleArt/promising_young_woman_title_art.png",
  "assets/Titles/titleArt/the_third_day_title_art.png",
  "assets/icons/noun-classic-heart-outline-612687-FFFFFF.svg",
  "assets/icons/noun-pencil-1778349-FFFFFF.svg",
  "assets/icons/noun-thumbs-down-996748-FFFFFF.svg",
  "assets/icons/noun-thumbs-up-996752-FFFFFF.svg",
  "assets/movies/calm/calm.mpd",
  "assets/movies/chefs_table/chefs_table.mpd",
  "assets/movies/gangs_squished/gangs_squished.mpd",
  "assets/movies/jumanji/jumanji.mpd",
  "assets/movies/kc_cycling/kc_cycling.mpd",
  "assets/movies/little_birds/little_birds.mpd",
  "assets/movies/parks_and_recs_ep_02/parks_and_recs_ep_02.mpd",
  "assets/movies/strictly/strictly.mpd",
  "assets/movies/zwfit_parts/zwfit_parts.mpd",
  "assets/ui/BackButton.png",
  "assets/ui/TilesUI.png",
  "assets/ui/noun_back_2331690.svg",
  "assets/icons/palm.svg",
  // "fonts/SkyText_Lt.ttf",
  // "fonts/SkyText_Md.ttf",
  // "fonts/SkyText_Rg.ttf",
  "chefs_table_audio_dashinit.mp4",
  "assets/movies/chefs_table/chefs_table_audio_dash1.m4s",
  "assets/movies/chefs_table/chefs_table_video_2000_dash1.m4s",
  "https://cdn.jsdelivr.net/npm/@mediapipe/hands@0.4.1635986972/hands_solution_packed_assets_loader.js",
  "https://cdn.jsdelivr.net/npm/@mediapipe/hands@0.4.1635986972/hands_solution_simd_wasm_bin.js",
  "https://cdn.jsdelivr.net/npm/@mediapipe/hands@0.4.1635986972/hands.binarypb",
  "https://cdn.jsdelivr.net/npm/@mediapipe/hands@0.4.1635986972/hands_solution_packed_assets.data",
  "https://cdn.jsdelivr.net/npm/@mediapipe/hands@0.4.1635986972/hands_solution_simd_wasm_bin.wasm",
];
