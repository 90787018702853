import {
  EpisodeBlock,
  EpisodeData,
  Expression,
  LauncherUpdate,
  PaneData,
  PaneInteraction,
  PaneMode,
  PaneStage,
  PaneState,
  Point,
  GridPointerEvent,
  VideoInfo,
  PaneCommand,
} from "../types";
import PaneDimensions from "../utils/PaneDimensions";
import PaneModel from "../components/Pane/PaneModel";
import {
  uiHeight,
  uiWidth,
  content,
  backButtonRect,
  reactionButtonHotSpots,
} from "../constants";
import { Observable, Subject } from "rxjs";
import Episodes from "../data/Episodes";
import EpisodeModel from "../components/Episode/EpisodeModel";
import { Content } from "../data/sequences";
import {
  isPtInDimensions,
  isPtInsideRect,
  removeItems,
  scalePt,
} from "../utils";
import GridDragManager from "./GridDragManager";

const episodeWidth = 600;

class PaneManager {
  private _panes: PaneModel[] = [];
  private _episodes: EpisodeModel[] = [];
  private _idCount: number = 0;

  private _deleteEpisodesFlag = false;
  private _activePane: number | null = null;
  private _episodePane: number | null = null;
  private _openEpisode: string | null = null;
  private _paneMode: PaneMode = "KITCHEN_MODE";
  private _deleteList: number[] = [];
  private _scale: number = 1;
  private _backButtonActive = false;
  private _drawingAreaActive = false;

  private _lastPointerUpTimeStamp = 0;

  private _gridOffsetTarget = 0;
  private _revealTiles = false;
  private _showDebugCursor = false;

  private _clickedPaneId: null | number = null;

  private _tilesStatus: "SHOWN" | "HIDDEN" = "HIDDEN";
  private _pointerEvent$ = new Subject<GridPointerEvent>();

  private _update$: Subject<LauncherUpdate> = new Subject<LauncherUpdate>();
  private _interaction$: Subject<PaneInteraction> =
    new Subject<PaneInteraction>();

  public constructor() {
    this._interaction$.subscribe((next) => {
      this.handleInteractions(next);
    });
  }

  public get pointerEvent$(): Subject<GridPointerEvent> {
    return this._pointerEvent$;
  }

  private gridDragManager = new GridDragManager(
    this._update$,
    this.triggerUpdate.bind(this),
    episodeWidth
  );

  updateSideEpisodes(gridXOffset: number) {
    if (gridXOffset < 0) {
      const numberOfExtraRightEpisodes = Math.ceil(-gridXOffset / episodeWidth);
      // const diff = numberOfExtraRightEpisodes - this._panes.length + 3;
      for (let i = 0; i < numberOfExtraRightEpisodes; i++) {
        const dimensions: PaneDimensions = {
          left: 1920 + i * episodeWidth,
          top: 0,
          width: episodeWidth,
          height: uiHeight,
        };
        const videoInfo =
          Content[(i + 3 + this.gridDragManager.paneRotation) % 9];
        this.addMovieAsPoster(videoInfo, dimensions);
      }
    }
    if (gridXOffset > 0) {
      const numberOfExtraRightEpisodes = Math.ceil(gridXOffset / episodeWidth);
      // const diff = numberOfExtraRightEpisodes - this._panes.length + 3;
      for (let i = 0; i < numberOfExtraRightEpisodes; i++) {
        const dimensions: PaneDimensions = {
          left: -i * episodeWidth,
          top: 0,
          width: episodeWidth,
          height: uiHeight,
        };
        const index = (((9 - i) % 9) + 9) % 9;
        const videoInfo = Content[index];
        this.addMovieAsPoster(videoInfo, dimensions);
      }
    }
  }

  addMovieAsPoster(videoInfo: VideoInfo, dimensions: PaneDimensions) {
    const id = this._idCount++;
    const timeStamp = new Date().getTime();
    const startWithPoster = true;
    const model = new PaneModel(
      id,
      dimensions,
      videoInfo,
      timeStamp,
      startWithPoster
    );
    this._panes.push(model);
    this.triggerUpdate();
  }

  toggleTiles() {
    if (this._tilesStatus === "HIDDEN") {
      this._update$.next({ type: "revealTiles" });
      this._tilesStatus = "SHOWN";
    } else {
      this._update$.next({ type: "hideTiles" });
      this._tilesStatus = "HIDDEN";
    }
  }

  public set scale(n: number) {
    this._scale = n;
  }

  public get paneMode(): PaneMode {
    return this._paneMode;
  }

  public get activePane(): number | null {
    return this._activePane;
  }

  private set activePane(id: number | null) {
    this._activePane = id;
  }

  public changeMode(mode: PaneMode) {
    if (mode === this._paneMode) return;
    switch (mode) {
      case "KITCHEN_MODE":
        this.changeModeChunk(0);
        this._paneMode = "KITCHEN_MODE";
        break;
      case "WATCH_TOGETHER_MODE":
        this.changeModeChunk(1);
        this._paneMode = "WATCH_TOGETHER_MODE";
        break;
      case "BIKE_MODE":
        this.changeModeChunk(2);
        this._paneMode = "BIKE_MODE";
        break;
    }
  }

  changeModeChunk(chunk: number) {
    if (this._openEpisode !== null) {
      // TODO Get to closed state
    }
    for (let i = 0; i < 3; i++) {
      const vi = Content[i + 3 * chunk];
      const left = (i * uiWidth) / 3;
      const top = uiHeight;
      const width = uiWidth / 3;
      const height = uiHeight;
      const paneModel = this.createPane(left, top, width, height, vi);
      const target: PaneDimensions = {
        top: 0,
        left,
        width,
        height,
      };
      paneModel.setTarget(target, i * 0.2);

      const pane = this._panes[i];
      pane.setTarget(
        {
          top: -uiHeight,
          left: pane.current.left,
          width: pane.current.width,
          height: pane.current.height,
        },
        i * 0.2
      );
    }
    this._deleteList = [0, 1, 2];
    this.triggerAnimation(1.4);
  }

  triggerReaction(expression: Expression) {
    if (this.activePane) {
      const pane = this.findPane(this.activePane);
      pane?.info.command$.next({ command: "triggerReaction", expression });
    }
  }

  createPane(
    left: number,
    top: number,
    width: number,
    height: number,
    videoInfo: VideoInfo
  ): PaneModel {
    const id = this._idCount++;
    const dimensions: PaneDimensions = {
      top,
      left,
      width,
      height,
    };
    const now = new Date().getTime();
    const paneModel = new PaneModel(id, dimensions, videoInfo, now, true);
    this._panes.push(paneModel);
    return paneModel;
  }

  triggerAnimation(animationFraction = 1) {
    this._update$.next({
      panes: this.paneData,
      episodes: this.episodeData,
      triggerAnimation: true,
      animationTotalFraction: animationFraction,
      gridOffset: this.gridDragManager.gridEpisodeOffset,
      type: "full",
    });
  }

  triggerUpdate() {
    this._update$.next({
      panes: this.paneData,
      episodes: this.episodeData,
      triggerAnimation: false,
      gridOffset: this.gridDragManager.gridEpisodeOffset,
      type: "mini",
    });
  }

  handleInteractions(interaction: PaneInteraction) {
    // Only for Debug
    // if (interaction.action !== "globalPointerMove") {
    //  console.log("INTERACTION", interaction);
    // }

    const pane =
      typeof interaction.id === "number"
        ? this.findPane(interaction.id)
        : undefined;

    const now = new Date().getTime();

    switch (interaction.action) {
      case "click":
        if (!pane) return;
        if (pane.state === PaneState.Shut) {
          this.handlePaneClick(interaction.id);
        }
        break;
      case "episodeClick":
        if (!this.gridDragManager.dragNotClick) {
          if (interaction.id === this._openEpisode) {
            this.closeEpisode(interaction.id);
          } else {
            this.playEpisode(interaction.id);
          }
        }
        break;
      case "back":
        this.handlePaneBack(interaction.id);
        break;
      case "globalPointerUp":
        {
          this._lastPointerUpTimeStamp = now;
          this.interpretPointerUp(interaction.pt);
          const scaledPt = scalePt(interaction.pt, 1 / this._scale);

          // Used to debug cursor only
          this._pointerEvent$.next({
            type: "UP",
            pt: interaction.pt,
            scaledPt,
            id: "",
          });
        }
        break;
      case "globalPointerMove":
        {
          this.interpretPointerMove(interaction.pt);
          const scaledPt = scalePt(interaction.pt, 1 / this._scale);
          this._pointerEvent$.next({
            type: "MOVE",
            pt: interaction.pt,
            scaledPt,
            id: "",
          });
        }
        break;
      case "globalPointerDown":
        {
          this.interpretPointerDown(interaction.pt);
          const scaledPt = scalePt(interaction.pt, 1 / this._scale);
          this._pointerEvent$.next({
            type: "DOWN",
            pt: interaction.pt,
            scaledPt,
            id: "",
          });
        }
        break;
      case "BackButtonActive":
        this._backButtonActive = interaction.active;
        break;
      case "DrawingArea":
        this.handleDrawingAreaChange(interaction.active);
        break;
    }
  }

  handleDrawingAreaChange(value: boolean): void {
    this._drawingAreaActive = value;
  }

  isBackButtonVisible(): boolean {
    return this._backButtonActive;
  }

  insideBackButton(pt: Point): boolean {
    return isPtInsideRect(pt, backButtonRect);
  }

  insideReactionControls(pt: Point): boolean {
    if (this.activePane) {
      const pane = this.findPane(this.activePane);
      if (pane && pane.videoInfo.id === "strictlyComeDancing") {
        return pt.y > 879;
      }
    }
    return false;
  }

  interpretPointerDown(pt: Point): void {
    this.gridDragManager.dragNotClick = false;
    this._revealTiles = false;
    const scaledPt = scalePt(pt, 1 / this._scale);
    if (this._episodes.length > 0) {
      this.gridDragManager.handleStartDragEpisodes(scaledPt);
      return;
    }

    if (this._activePane === null) {
      const paneIds = this.locatePane(scaledPt);
      if (paneIds.length === 1) {
        this._clickedPaneId = paneIds[0];
        const pane = this.findPane(this._clickedPaneId);
        if (pane) pane.showHighlight();
      }
    }

    if (this._episodes.length === 0 && this._activePane === null) {
      this.gridDragManager.handleStartDragGrid(scaledPt);
      return;
    }
  }

  interpretPointerMove(pt: Point) {
    if (this.gridDragManager.startDragInfo) {
      const scaledPt = scalePt(pt, 1 / this._scale);
      if (this.gridDragManager.startDragInfo.episodes) {
        this.gridDragManager.handleDragEpisodes(
          scaledPt,
          this._episodes.length
        );
      } else {
        const gridXYOffset = this.gridDragManager.handleDragMainGrid(scaledPt);
        this.updateSideEpisodes(gridXYOffset.x);
      }
    }
  }

  clearHighlightedPane() {
    this._panes.forEach((p) => {
      p.hideHighlight();
    });
  }

  sendCommandToActivePane(command: PaneCommand) {
    let pane = undefined;
    if (this.activePane) pane = this.findPane(this.activePane);
    pane?.info.command$.next(command);
  }

  interpretPointerUp(pt: Point) {
    this.clearHighlightedPane();
    const draggingHappened = this.gridDragManager.stopDrag();
    if (draggingHappened) return;
    let lastDownClickedPane = this._clickedPaneId;
    this._clickedPaneId = null;
    if (this.gridDragManager.dragNotClick) return;
    if (this._revealTiles) return;
    const scaledPt = scalePt(pt, 1 / this._scale);

    // Detect if reaction buttons have been pressed
    if (this.isReactionScreen()) {
      const reactionButton = this.checkReactionButtons(scaledPt);
      if (reactionButton !== null) {
        if (reactionButton === "pencil") {
          if (!this._drawingAreaActive) {
            this.sendCommandToActivePane({ command: "enableDraw" });
          } else {
            this.sendCommandToActivePane({ command: "disableDraw" });
          }
        } else {
          this.triggerReaction(reactionButton);
        }
        return;
      }
    }

    // TODO squish reaction button when down pressed

    if (this.isBackButtonVisible() && this.insideBackButton(scaledPt)) {
      this.handlePaneBack(this.activePane);
      return;
    }

    const paneIds = this.locatePane(scaledPt);
    if (paneIds.length === 1 && !this._drawingAreaActive) {
      if (this._activePane !== null || lastDownClickedPane === paneIds[0]) {
        this.handlePaneClick(paneIds[0]);
      }
    }
  }

  isReactionScreen(): boolean {
    if (this.activePane === null) return false;
    const pane = this.findPane(this.activePane);
    if (!pane) return false;
    return pane.info.videoInfo.id === "strictlyComeDancing";
  }

  checkReactionButtons(pt: Point): Expression | null {
    let result: Expression | null = null;
    reactionButtonHotSpots.forEach((h) => {
      if (isPtInsideRect(pt, h.rect)) {
        result = h.label;
      }
    });
    return result;
  }

  locatePane(pt: Point): number[] {
    const result: number[] = [];
    this._panes.forEach((p) => {
      if (isPtInDimensions(pt, p.current)) {
        result.push(p.id);
      }
    });
    return result;
  }

  locateEpisode(pt: Point): string[] {
    const result: string[] = [];
    this._episodes.forEach((e) => {
      if (isPtInDimensions(pt, e.current)) {
        result.push(e.id);
      }
    });
    return result;
  }

  public closeEpisode(id: string) {
    const episodeIndex = this._episodes.findIndex((e) => e.id === id);
    if (episodeIndex === -1) return;
    const minLeft = episodeWidth;
    this._episodes.forEach((ep, idx) => {
      const endDimensions: PaneDimensions = {
        top: 0,
        left: minLeft + idx * episodeWidth,
        width: episodeWidth,
        height: uiHeight,
      };
      ep.setTarget(endDimensions);
    });

    if (this._episodePane) {
      const pane = this.findPane(this._episodePane);
      if (pane) {
        pane.setTarget({
          top: 0,
          height: uiHeight,
          left: 0,
          width: minLeft,
        });
      }
    }

    this.activePane = this._episodePane;
    this._episodePane = null;
    this._openEpisode = null;

    const episode = this._episodes[episodeIndex];
    episode.pauseVideo();
    episode.showPoster();

    this.triggerAnimation();
  }

  public launchContent(episodeId: string, showEpisodes = false) {
    const [mode, paneId] = (Object.keys(content) as PaneMode[]).reduce<
      [PaneMode | undefined, string | undefined, string[] | undefined]
    >(
      (prev, _, idx, modes) => {
        const [pane, episodes] = Object.entries(content[modes[idx]]).find(
          ([_, episodes]) => episodes.includes(episodeId)
        ) || [undefined, undefined];
        if (prev.some((value) => value === undefined)) {
          return [modes[idx], pane, episodes];
        }
        return prev;
      },
      [undefined, undefined, undefined]
    );

    if (mode) {
      const timeout = this._paneMode !== mode ? 2750 : 0;
      this.changeMode(mode);

      if (paneId) {
        const pane = this.findContent(paneId);
        if (pane) {
          setTimeout(() => {
            if (pane.videoInfo.containsApps || showEpisodes) {
              this.showEpisodesForPane(pane);
            } else {
              this.openAndPlayVideo(pane);
            }
          }, timeout);
        }
      }
    }
  }

  public playEpisode(id: string) {
    const episodeIndex = this._episodes.findIndex((e) => e.id === id);
    if (episodeIndex === -1) return "error";

    for (let i = 0; i < this._episodes.length; i++) {
      let endDimensions: PaneDimensions;
      if (i < episodeIndex) {
        endDimensions = {
          top: 0,
          left: -uiWidth,
          width: uiWidth,
          height: uiHeight,
        };
      } else if (i === episodeIndex) {
        endDimensions = {
          top: 0,
          left: 0,
          width: uiWidth,
          height: uiHeight,
        };
      } else {
        endDimensions = {
          top: 0,
          left: uiWidth,
          width: uiWidth,
          height: uiHeight,
        };
      }
      const episode = this._episodes[i];
      episode.setTarget(endDimensions);
    }

    if (this.activePane !== null) {
      const pane = this.findPane(this.activePane);
      if (pane) {
        const endDimensions = {
          top: 0,
          left: -uiWidth,
          width: uiWidth,
          height: uiHeight,
        };
        pane.setTarget(endDimensions);
      }
    }

    this.triggerAnimation();

    const episode = this._episodes[episodeIndex];
    episode.playVideo();

    this._openEpisode = id;
    this._episodePane = this.activePane;
    this.activePane = null;
  }

  handlePaneBack(id: number | null) {
    let pane: PaneModel | undefined;
    let trueId: number | null = null;

    if (id !== null) {
      pane = this.findPane(id);
      trueId = id;
    } else {
      if (this.activePane !== null) {
        pane = this.findPane(this.activePane);
        if (pane) trueId = pane.id;
      }
    }

    if (pane === undefined || trueId === null) return;

    if (pane.stage === PaneStage.Paused) {
      pane.showPoster();
      this.closePane(trueId);
    }

    if (pane.stage === PaneStage.Playing) {
      pane.pauseVideo();
      pane.showPoster();
      this.closePane(trueId);
    }

    if (pane.stage === PaneStage.Episodes) {
      pane.backFromEpisodeMode();
      this.closePane(trueId);
    }
  }

  handlePaneClick(id: number) {
    const pane = this.findPane(id);
    if (!pane) return;

    if (pane.state === PaneState.Shut) {
      if (pane.videoInfo.containsApps) {
        this.showEpisodesForPane(pane);
      } else {
        this.openAndPlayVideo(pane);
      }
      return;
    }

    if (pane.state === PaneState.Open) {
      pane.showVideoControls();
    }

    // if (pane.stage === PaneStage.Playing && pane.state === PaneState.Open) {
    //   pane.pauseVideo();
    //   this.triggerUpdate();
    //   return;
    // }

    // if (pane.stage === PaneStage.Paused && pane.state === PaneState.Open) {
    //   pane.resumeVideo();
    //   this.triggerUpdate();
    //   return;
    // }
  }

  public openAndPlayVideo(pane?: PaneModel) {
    if (pane) {
      this.openPane(pane.id);
      pane.playVideo();
      this.triggerUpdate();
    } else if (this.activePane !== null) {
      const pane = this.findPane(this.activePane);
      if (pane?.stage === PaneStage.Playing) {
        pane.pauseVideo();
      } else if (pane?.stage === PaneStage.Paused) {
        pane.resumeVideo();
      }
    }
  }

  findContent(videoInfoId: string): PaneModel | undefined {
    return this._panes.find((p) => p.videoInfo.id === videoInfoId);
  }

  findPane(id: number = this.activePane ?? -1): PaneModel | undefined {
    return this._panes.find((p) => p.id === id);
  }

  closePane(id: number) {
    this.gridDragManager.dragNotClick = false;
    const thirdOfWidth = uiWidth / 3;
    this._panes.forEach((p, idx) => {
      p.setTarget({
        top: 0,
        left: idx * thirdOfWidth,
        width: thirdOfWidth,
        height: uiHeight,
      });
      p.state = PaneState.Shut;
    });
    this.removeEpisodes(id);
    this.triggerAnimation();
    this.activePane = null;
  }

  openPane(id: number, completeCallback?: () => void): void {
    const panePosition = this._panes.findIndex((p) => p.id === id);
    if (panePosition === -1) return;
    this._panes.forEach((p, idx) => {
      if (idx < panePosition) {
        const newX = ((panePosition - idx) * uiWidth) / 3;
        p.setTarget({
          top: 0,
          left: -newX,
          width: uiWidth / 3,
          height: uiHeight,
        });
        p.state = PaneState.Shut;
      } else if (idx === panePosition) {
        p.setTarget(
          {
            top: 0,
            left: 0,
            width: uiWidth,
            height: uiHeight,
          },
          0,
          completeCallback
        );
        p.state = PaneState.Open;
      } else {
        const newX = uiWidth + ((idx - panePosition - 1) * uiWidth) / 3;
        p.setTarget({
          top: 0,
          left: newX,
          width: uiWidth / 3,
          height: uiHeight,
        });
        p.state = PaneState.Shut;
      }
    });
    this.triggerAnimation();
    this.activePane = id;
  }

  removeEpisodes(id: number, triggerAnimation = false): void {
    this._episodes.forEach((e, idx) => {
      const target: PaneDimensions = {
        top: 0,
        left: uiWidth + idx * episodeWidth * 2,
        height: uiHeight,
        width: episodeWidth,
      };
      e.setTarget(target);
    });

    this._deleteEpisodesFlag = true;

    if (triggerAnimation) {
      this.triggerAnimation();
    }
  }

  showEpisodesForColumn(column: number) {
    const pane = this._panes[column];
    if (!pane) return;
    this.showEpisodesForPane(pane);
  }

  showEpisodesForPane(pane: PaneModel) {
    const episodeBlock: EpisodeBlock | undefined = Episodes.find(
      (e) => e.id === pane.videoInfo.id
    );
    if (!episodeBlock) return;

    const self = this;

    this.openPane(pane.id, function () {
      setTimeout(() => {
        self.addEpisodes(pane.id);
      }, 1);
    });
  }

  addEpisodes(id: number): void {
    const pane = this.findPane(id);
    if (pane === undefined) return;
    const showId = pane.videoInfo.id;
    const episodeBlock: EpisodeBlock | undefined = Episodes.find(
      (e) => e.id === showId
    );
    if (!episodeBlock) return;
    const episodeModels: EpisodeModel[] = [];
    const delayFrac = 0.05;
    const minLeft = episodeWidth;

    pane.notifyShowingEpisodes();

    episodeBlock.episodes.forEach((e, idx) => {
      const startDimensions: PaneDimensions = {
        top: 0,
        left: uiWidth + idx * uiWidth,
        height: uiHeight,
        width: uiWidth,
      };
      const endDimensions: PaneDimensions = {
        top: 0,
        left: minLeft + idx * episodeWidth,
        width: episodeWidth,
        height: uiHeight,
      };
      const episodeId = `episode-${idx}`;
      const ep = new EpisodeModel(
        episodeId,
        startDimensions,
        e.titles,
        e.poster,
        e.content,
        new Date().getTime(),
        e.isApp
      );
      const delay = idx * delayFrac;
      ep.setTarget(endDimensions, delay);
      episodeModels.push(ep);
    });
    this._episodes = episodeModels;

    // Update the pane

    const endDimensions = {
      top: 0,
      left: 0,
      width: minLeft,
      height: uiHeight,
    };
    if (pane) {
      pane.setTarget(endDimensions);
    }

    this._update$.next({
      panes: this.paneData,
      episodes: this.episodeData,
      triggerAnimation: true,
      animationTotalFraction: 1 + delayFrac * 2,
      durationSecs: 2,
      type: "full",
      gridOffset: this.gridDragManager.gridEpisodeOffset,
    });
  }

  addMovieWithOpenningAnimation(videoInfo: VideoInfo) {
    const prevNoPanes = this._panes.length;
    const noPanes = prevNoPanes + 1;
    const id = this._idCount++;
    const left = noPanes === 1 ? 0 : uiWidth;
    const dimensions: PaneDimensions = {
      top: 0,
      left,
      width: uiWidth,
      height: uiHeight,
    };

    const now = new Date().getTime();
    const panelModel = new PaneModel(id, dimensions, videoInfo, now, false);
    this._panes.push(panelModel);

    let triggerAnimation = false;

    if (noPanes > 1) {
      triggerAnimation = true;
      this._panes.forEach((p, i) => {
        let width = uiWidth / 3;
        if (i === 1 && noPanes === 2) {
          width = (2 * uiWidth) / 3;
        }
        p.setTarget({
          top: 0,
          left: (i * uiWidth) / 3,
          width,
          height: uiHeight,
        });
      });
    }

    if (triggerAnimation) {
      this.triggerAnimation();
    } else {
      this.triggerUpdate();
    }
  }

  get update$(): Observable<LauncherUpdate> {
    return this._update$ as Observable<LauncherUpdate>;
  }

  get paneData(): PaneData[] {
    return this._panes.map((p) => p.info);
  }

  get episodeData(): EpisodeData[] {
    return this._episodes.map((e) => e.info);
  }

  handleAnimation(v: number): void {
    this._panes.forEach((p) => {
      p.setAnimationValue(v);
    });
    this._episodes.forEach((e) => {
      e.setAnimationValue(v);
    });
    this.gridDragManager.gridEpisodeOffset =
      this.gridDragManager.gridEpisodeOffsetStart +
      v *
        (this._gridOffsetTarget - this.gridDragManager.gridEpisodeOffsetStart);

    this.triggerUpdate();
  }

  handleAnimationComplete() {
    this._panes.forEach((p) => {
      p.completeAnimation();
    });
    this._episodes.forEach((e) => {
      e.completeAnimation();
    });
    this.gridDragManager.gridEpisodeOffsetStart =
      this.gridDragManager.gridEpisodeOffset = this._gridOffsetTarget;

    if (this._deleteEpisodesFlag) {
      this._deleteEpisodesFlag = false;
      this._episodes = [];
    }
    if (this._deleteList.length > 0) {
      this._panes = removeItems(this._panes, this._deleteList);
      this.triggerUpdate();
    }
    this._deleteList = [];
  }

  handleAnimationStop() {
    this.handleAnimationComplete();
  }

  public get interaction$(): Subject<PaneInteraction> {
    return this._interaction$;
  }

  public updateTime(now: number) {
    this._panes.forEach((p) => {
      p.updateTime(now);
    });
  }

  handleToggleShowPointer() {
    this._showDebugCursor = !this._showDebugCursor;
    this._update$.next({ type: "showCursor", value: this._showDebugCursor });
  }
}

export default PaneManager;
