import styled from "@emotion/styled";

export const Container = styled.div`
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  font-size: 26pt;
  font-family: "Sky Text Light", sans-serif;
  grid-gap: 1.5rem;
  padding: 3.5rem 5.5rem 0 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const Icon = styled.div`
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 100%;
  display: flex;
  height: 65px;
  justify-content: center;
  width: 65px;
`;

export const Wrapper = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 2;

  &::before {
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(8, 9, 15, 0.5) 30%,
      rgba(8, 9, 15, 0.8) 80%
    );
    content: "";
    height: 200px;
    position: absolute;
    width: 100%;
  }
`;
