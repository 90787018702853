import { Container, Row, Row2 } from "./index.styles";
import { ReactionButton } from "../ReactionButton";
import { HoldButton } from "../HoldButton";
import {
  ReactionButtonAction,
  Expression,
  ReactionUICommand,
} from "../../types";
import { FC, useEffect, useRef } from "react";
import { Observable, Subject } from "rxjs";

export type Props = {
  callback: (v: Expression, hold: boolean) => void;
  commands: Observable<ReactionUICommand>;
};

function generateStreams() {
  const expressions: Expression[] = ["pencil", "thumbUp", "thumbDown", "heart"];
  return expressions.map((ex) => ({
    label: ex,
    subject: new Subject<ReactionButtonAction>(),
  }));
}

export const ReactionControls: FC<Props> = ({ callback, commands }) => {
  const commandStreamsRef = useRef(generateStreams());

  useEffect(() => {
    // TODO Clean up this spaghetti
    const subscription = commands.subscribe((next) => {
      switch (next.button) {
        case "pencil":
          if (commandStreamsRef.current) {
            const s = commandStreamsRef.current[0].subject;
            s.next(next.action);
          }
          break;
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [commands]);

  return (
    <Container>
      <Row>
        <HoldButton
          type={"pencil"}
          callback={callback}
          commands={commandStreamsRef.current[0].subject}
        />
      </Row>
      <Row2>
        <ReactionButton
          type={"thumbUp"}
          callback={(v) => {
            callback(v, false);
          }}
        />
        <ReactionButton
          type={"heart"}
          callback={(v) => {
            callback(v, false);
          }}
        />
        <ReactionButton
          type={"thumbDown"}
          callback={(v) => {
            callback(v, false);
          }}
        />
      </Row2>
    </Container>
  );
};
