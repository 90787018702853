import { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { ReactionButtonAction, Expression } from "../../types";
import { Observable } from "rxjs";

export type Props = {};

const icons = {
  pencil: {
    img: "assets/icons/noun-pencil-1778349-FFFFFF.svg",
    alt: "pencil",
  },
  heart: {
    img: "assets/icons/noun-classic-heart-outline-612687-FFFFFF.svg",
    alt: "heart",
  },
  thumbUp: {
    img: "assets/icons/noun-thumbs-up-996752-FFFFFF.svg",
    alt: "thumb up",
  },
  thumbDown: {
    img: "assets/icons/noun-thumbs-down-996748-FFFFFF.svg",
    alt: "thumb down",
  },
};

export type ButtonIcon = "pencil" | "heart" | "thumbUp" | "thumbDown";

type ButtonProps = {
  type: ButtonIcon;
  callback: (v: Expression, hold: boolean) => void;
  commands: Observable<ReactionButtonAction>;
};

export const HoldButton: FC<ButtonProps> = ({ type, callback, commands }) => {
  const [held, setHeld] = useState<boolean>(false);

  function handleClick(e: any) {
    setHeld((value) => {
      callback(type, !value);
      return !value;
    });
    if (e !== null) {
      e.stopPropagation();
    }
  }

  const backgroundOpacity = held ? 0.6 : 0.3;
  const border = held ? "2px solid white " : "none";

  useEffect(() => {
    const subscription = commands.subscribe((next) => {
      if (next === "clickUp") {
        setHeld((oldValue) => {
          callback(type, !oldValue);
          return !oldValue;
        });
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [commands, callback, type]);

  return (
    <motion.button
      style={{
        width: 120,
        height: 120,
        backgroundColor: `rgba(255,255,255,${backgroundOpacity})`,
        border,
        borderRadius: 16,
      }}
      whileTap={{ scale: 0.9 }}
    >
      <img
        src={icons[type].img}
        alt={icons[type].alt}
        width={90}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleClick(e);
        }}
      />
    </motion.button>
  );
};
