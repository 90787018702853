import { PaneData, PaneStage, PaneState, VideoInfo } from "../../types";

import PaneDimensions from "../../utils/PaneDimensions";

import MovingRectWithVideoContent from "../Episode/MovingRectWithVideoContent";

class PaneModel extends MovingRectWithVideoContent {
  private _state: PaneState = PaneState.Shut;
  private readonly _id: number;
  private readonly _videoInfo: VideoInfo;
  private _highlight: boolean = false;

  private _xOffset: number = 0;

  constructor(
    id: number,
    dimensions: PaneDimensions,
    videoInfo: VideoInfo,
    timeStamp: number,
    startWithPoster: boolean
  ) {
    super(dimensions, timeStamp, startWithPoster);
    this._id = id;
    this._videoInfo = videoInfo;
    this._startWithPoster = startWithPoster;
  }

  public set state(value: PaneState) {
    if (this._state !== value) {
      this._state = value;
      switch (this._state) {
        case "open":
          this._command$.next({ command: "open" });
          break;
        case "shut":
          this._command$.next({ command: "shut" });
          break;
      }
    }
  }

  public get state(): PaneState {
    return this._state;
  }

  public get info(): PaneData {
    return {
      dimensions: this._current,
      id: this._id,
      videoInfo: this._videoInfo,
      command$: this._command$,
      state: this._state,
      startWithPoster: this._startWithPoster,
      xOffset: this._xOffset,
      showBackButton: this.isBackButtonVisible(),
    };
  }

  public get id(): number {
    return this._id;
  }

  public get videoInfo() {
    return this._videoInfo;
  }

  public get xOffset(): number {
    return this._xOffset;
  }

  public increaseXOffset(x: number) {
    this._xOffset += x;
  }

  public set xOffset(x: number) {
    this._xOffset = x;
  }

  public isBackButtonVisible() {
    return this._state === PaneState.Open && this._stage === PaneStage.Paused;
  }

  public showVideoControls(): void {
    this._command$.next({ command: "showVideoControls" });
  }

  public hideVideoControls(): void {
    this._command$.next({ command: "hideVideoControls" });
  }

  public showHighlight(): void {
    this._highlight = true;
    this._command$.next({ command: "showHighlight" });
  }

  public hideHighlight(): void {
    this._highlight = false;
    this._command$.next({ command: "hideHighlight" });
  }
}

export default PaneModel;
