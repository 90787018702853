import styled from "@emotion/styled";

export const Container = styled.div`
  position: absolute;
  top: 200px;
  left: 100px;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  padding: 0 0 60px 0;
  display: grid;
  grid-template-columns: 1fr
`
export const Row2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

