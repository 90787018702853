import styled from "@emotion/styled";

export const Container = styled.div`
  video {
    object-fit: cover;
  }
  width: 100%;
  height: 100%;
  position:absolute;
`;

export const VideoHolder = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
