import React from "react";
import { Subject } from "rxjs";

import type { VideoCommand, VideoProgressInfo } from "../../types";
import { PlaybackUI, PlaybackUIProps } from "../PlaybackUI";
import { Body, Container, Footer, Header } from "./index.styles";

type Props = Omit<PlaybackUIProps, "fraction" | "duration"> & {
  active: boolean;
  instruction$: Subject<VideoCommand>;
  muted?: boolean;
  progress?: VideoProgressInfo;
  style?: React.CSSProperties;
  title: string;
};

const PlayerOverlay: React.FC<Props> = ({
  active,
  instruction$,
  muted,
  progress,
  style,
  title,
  ...rest
}) => {
  const sendCommand = (command: VideoCommand) => {
    if (active) {
      instruction$.next(command);
    }
  };

  return (
    <Container style={style}>
      <Header>
        <p
          className="icon"
          style={{ width: "46px" }}
          onClick={() => {
            sendCommand({ action: "back" });
          }}
        >
          ←
        </p>
        <p>{title}</p>
        <p
          className="icon"
          style={{ width: "46px" }}
          onClick={() => {
            sendCommand({ action: "mute" });
          }}
        >
          {muted ? "" : ""}
        </p>
      </Header>
      <Body>
        <p
          className="icon"
          onClick={() => {
            sendCommand({
              action: "seek",
              location: (progress?.playedSeconds ?? 0) - 30,
            });
          }}
        >
          
        </p>
        <p
          className="icon"
          style={{ fontSize: "8rem" }}
          onClick={() => {
            sendCommand({
              action: rest.icon === "play" ? "pause" : "play",
            });
          }}
        >
          {rest.icon === "play" ? "" : ""}
        </p>
        <p
          className="icon"
          onClick={() => {
            sendCommand({
              action: "seek",
              location: (progress?.playedSeconds ?? 0) + 30,
            });
          }}
        >
          
        </p>
      </Body>
      <Footer>
        <PlaybackUI
          duration={(progress?.playedSeconds ?? 0) / (progress?.played ?? 0)}
          fraction={progress?.played ?? 0}
          {...rest}
        />
      </Footer>
    </Container>
  );
};

export default PlayerOverlay;
