import { backButtonRect, reactionButtonHotSpots } from "../../constants";
import { Rectangle } from "../../types";

function rr(r: Rectangle, key: string) {
  return (
    <rect
      x={r.x}
      y={r.y}
      width={r.width}
      height={r.height}
      key={key}
      fill={"rgba(255,128,0,0.5)"}
    />
  );
}

const DebugHotSpots = () => (
  <div style={{ position: "absolute", pointerEvents: "none" }}>
    <svg width={1920} height={1080}>
      {rr(backButtonRect, "backButtonRect")}
      {reactionButtonHotSpots.map((data) => rr(data.rect, data.label))}
    </svg>
  </div>
);

export default DebugHotSpots;
