import styled from "@emotion/styled";
import { uiHeight, uiWidth } from "../../constants";

type Props = {
  left: number;
  top: number;
  visible?: boolean;
};

export const Container = styled.div<Props>`
  width: ${uiWidth}px;
  height: ${uiHeight}px;
  overflow: hidden;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  position: absolute;
`;

export const Container2 = styled.div<Props>`
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  display: ${(props) =>
    props.visible === undefined || props.visible !== false ? "block" : "none"};
  position: absolute;
`;
