import { Observable, ReplaySubject } from "rxjs";
import {
  ControlsAnimationDefinition,
  AnimationControls,
} from "framer-motion/types/animation/types";
import { useAnimation } from "framer-motion";
import { useEffect } from "react";
import { Point, Rectangle } from "./types";
import PaneDimensions from "./utils/PaneDimensions";

export function useAnimationStream(
  update$: Observable<ControlsAnimationDefinition>
): AnimationControls {
  const controls = useAnimation();
  useEffect(() => {
    const subscription = update$.subscribe((next) => {
      controls.start(next);
    });
    return () => {
      subscription.unsubscribe();
    };
  });
  return controls;
}

export function createAnimationStream(): ReplaySubject<ControlsAnimationDefinition> {
  return new ReplaySubject<ControlsAnimationDefinition>();
}

// Date & Time Functions

export function startOfDay(): Date {
  const start = new Date();
  start.setHours(0, 0, 0, 0);
  return start;
}

export function todayAt(hours: number, minutes: number): Date {
  const start = new Date();
  start.setHours(hours, minutes, 0, 0);
  return start;
}

export function subtractDates(d1: Date, d2: Date): number {
  return d1.getTime() - d2.getTime();
}

export function addMilliseconds(d: Date, millis: number): Date {
  const t = d.getTime() + millis;
  return new Date(t);
}

export function getFormattedDuration(duration: number) {
  var hrs = ~~(duration / 3600);
  var mins = ~~((duration % 3600) / 60);
  var secs = ~~duration % 60;

  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

export function getFormattedTime(d: Date): string {
  const h = d.getHours();
  const m = d.getMinutes();
  const hh = h % 12;
  const suffix = h > 11 ? "pm" : "am";
  const mm = m < 10 ? `0${m}` : m.toString();
  return `${hh}:${mm}${suffix}`;
}

export function tweenTime(k: number, d1: Date, d2: Date): Date {
  const diff = subtractDates(d2, d1);
  const t = diff * k + d1.getTime();
  const newDate = new Date(t);
  return newDate;
}

export function removeItems<T>(list: T[], deleteList: number[]): T[] {
  const nuList: T[] = [];
  list.forEach((t, idx) => {
    const found = deleteList.includes(idx);
    if (!found) {
      nuList.push(t);
    }
  });
  return nuList;
}

export function betterSubStr(str: string, start: number, end?: number) {
  const l = str.length;
  let i1 = start;
  let i2 = end;
  if (start < 0) {
    i1 = l + start;
  }
  if (end !== undefined && end < 0) {
    i2 = l + end + 1;
  }
  return str.substring(i1, i2);
}

export function isPtInDimensions(pt: Point, d: PaneDimensions): boolean {
  const h = pt.x >= d.left && pt.x <= d.left + d.width;
  const v = pt.y >= d.top && pt.y <= d.top + d.height;
  return v && h;
}

export function scalePt(pt: Point, scale: number): Point {
  const x = pt.x * scale;
  const y = pt.y * scale;
  return { x, y };
}

export function isPtInsideRect(pt: Point, rect: Rectangle) {
  const h = pt.x >= rect.x && pt.x <= rect.x + rect.width;
  const v = pt.y >= rect.y && pt.y <= rect.y + rect.height;
  return v && h;
}

export function subtractPts(a: Point, b: Point): Point {
  return { x: a.x - b.x, y: a.y - b.y };
}

export function magnitudePt(a: Point): number {
  return Math.sqrt(a.x * a.x + a.y * a.y);
}
