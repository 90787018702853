import MovingRectangle from "./MovingRectangle";
import { PaneCommand, PaneStage } from "../../types";
import { BehaviorSubject } from "rxjs";
import { panePreviewDuration } from "../../constants";
import PaneDimensions from "../../utils/PaneDimensions";

class MovingRectWithVideoContent extends MovingRectangle {
  protected _command$ = new BehaviorSubject<PaneCommand>({ command: "none" });
  protected _stage: PaneStage = PaneStage.Preview;
  protected _preEpisodeStage: PaneStage = PaneStage.Episodes;
  protected _startWithPoster: boolean = false;

  private readonly _startPreviewTime;
  private _now: number;

  private previewFinished = false;

  constructor(
    dimensions: PaneDimensions,
    timeStamp: number,
    startWithPoster?: boolean
  ) {
    super(dimensions);
    if (startWithPoster !== undefined) {
      this._startWithPoster = startWithPoster;
    }
    this._startPreviewTime = timeStamp;
    this._now = timeStamp;
  }

  public updateTime(now: number): void {
    this._now = now;
    if (!this.previewFinished && !this._startWithPoster) {
      const diff = this._now - this._startPreviewTime;
      if (diff > panePreviewDuration) {
        this.previewFinished = true;
        this._command$.next({
          command: "showPoster",
        });
      }
    }
  }

  public showPoster(): void {
    if (this._stage === PaneStage.Paused) {
      this._stage = PaneStage.Poster;
      this._command$.next({ command: "showPoster" });
    }
  }

  public playVideo(): void {
    this._stage = PaneStage.Playing;
    this._command$.next({ command: "playVideo" });
  }

  public pauseVideo(): void {
    if (this._stage === PaneStage.Playing) {
      this._stage = PaneStage.Paused;
      this._command$.next({ command: "pauseVideo" });
    }
  }

  public resumeVideo(): void {
    if (this._stage === PaneStage.Paused) {
      this._stage = PaneStage.Playing;
      this._command$.next({ command: "resumeVideo" });
    }
  }

  public skipForward(): void {
    this._command$.next({ command: "skipForward" });
  }

  public skipBack(): void {
    this._command$.next({ command: "skipBack" });
  }

  public notifyShowingEpisodes(): void {
    this.episodeMode();
    this._command$.next({ command: "notifyShowingEpisodes" });
  }

  public episodeMode(): void {
    this._preEpisodeStage = this._stage;
    this._stage = PaneStage.Episodes;
  }

  public backFromEpisodeMode(): void {
    this._stage = this._preEpisodeStage;
    this._preEpisodeStage = PaneStage.Episodes;
  }

  public get stage() {
    return this._stage;
  }
}

export default MovingRectWithVideoContent;
