import { catchError, concatMap, from, Observable, of } from "rxjs";

export type PreloadInfo = {
  percent: number;
  total: number;
  count: number;
  complete: boolean;
  error: boolean;
};

function loadFile(filePath: string): Observable<HTMLLinkElement> {
  return new Observable((subscriber) => {
    const link = document.createElement("link");
    link.rel = "preload";
    link.href = filePath;
    link.as = ["jpeg", "jpg", "svg", "png"].includes(
      filePath.split(".").pop() ?? ""
    )
      ? "image"
      : "fetch";
    link.onload = function () {
      subscriber.next(link);
      subscriber.complete();
    };
    link.onerror = function (err) {
      subscriber.error(err);
    };
    document.head.appendChild(link);
  });
}

class PreloaderLogic {
  private readonly _fileList: string[];
  private count = 0;
  private errors = 0;

  public progressCallback: ((data: PreloadInfo) => void) | undefined =
    undefined;

  public constructor(
    fileList: string[],
    callback: (data: PreloadInfo) => void
  ) {
    this._fileList = fileList;
    this.progressCallback = callback;
  }

  countFiles(error = false) {
    this.count += 1;
    const l = this._fileList.length;
    const percent = (100 * this.count) / l;
    if (this.progressCallback)
      this.progressCallback({
        percent,
        total: l,
        count: this.count,
        complete: this.count === l,
        error,
      });
  }

  public preload(): void {
    this.count = 0;
    this.errors = 0;
    const fileStream = from(this._fileList);
    fileStream
      .pipe(
        //mergeMap(loadImage),
        concatMap((value) => {
          return loadFile(value).pipe(
            catchError((val) =>
              of(`Preloader Error: ${val.path[0].currentSrc}`)
            )
          );
        })
      )
      .subscribe((next) => {
        const error = typeof next === "string";
        if (error) console.error(next);
        this.countFiles(error);
      });
  }
}

export default PreloaderLogic;
