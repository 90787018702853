import React, { useEffect, useState } from "react";

import { Container } from "./Autosize.styles";
import { getWindowSize } from "../utils/windowSize";

export const Autosize: React.FC<{
  resize: boolean;
  onResize?: (scale: number) => void;
  dimensions: { width: number; height: number; x: number; y: number };
}> = ({ dimensions, resize, onResize, children }) => {
  const [scale, setScale] = useState(1);

  const onResizeCallback = React.useCallback(
    (scale) => {
      if (onResize) onResize(scale);
    },
    [onResize]
  );

  const fillWindow = React.useCallback(() => {
    const { width, height } = getWindowSize();
    const s = Math.min(width / dimensions.width, height / dimensions.height);
    setScale(s);
    onResizeCallback(s);
  }, [onResizeCallback, dimensions.width, dimensions.height]);

  useEffect(() => {
    if (resize) {
      window.addEventListener("resize", fillWindow);
      fillWindow();
      return function cleanup() {
        window.removeEventListener("resize", fillWindow);
      };
    } else {
      setScale(1);
      onResizeCallback?.(1);
    }
  }, [resize, fillWindow, onResizeCallback]);

  return <Container scale={scale}>{children}</Container>;
};
