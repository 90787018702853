import { FC, useEffect, useState } from "react";
import { GridPointerEvent, Point } from "../../types";
import { Observable } from "rxjs";

type Props = {
  showCursor: boolean;
  pointerEvent$: Observable<GridPointerEvent>;
};

type SpotInfo = {
  downPoint: Point;
  upPoint: Point;
  movePoint: Point;
  last: GridPointerEvent[];
};

const TextThing: FC<{ textBit: string; y: number; x: number }> = ({
  x,
  y,
  textBit,
}) => (
  <text x={x} y={y} fill="white" fontSize={16} fontFamily={"courier"}>
    {textBit}
  </text>
);

const DebugCursor: FC<Props> = ({ pointerEvent$, showCursor }) => {
  const [spot, setSpot] = useState<Point>({ x: 300, y: 300 });
  const [spotInfo, setSpotInfo] = useState<SpotInfo>({
    downPoint: { x: 0, y: 0 },
    upPoint: { x: 0, y: 0 },
    movePoint: { x: 0, y: 0 },
    last: [],
  });

  useEffect(() => {
    const subscription = pointerEvent$.subscribe((next) => {
      setSpot(next.scaledPt);

      setSpotInfo((oldInfo) => {
        const nuInfo = { ...oldInfo };
        switch (next.type) {
          case "DOWN":
            nuInfo.downPoint = next.scaledPt;
            break;
          case "UP":
            nuInfo.upPoint = next.scaledPt;
            break;
          case "MOVE":
            nuInfo.movePoint = next.scaledPt;
            break;
        }
        nuInfo.last = [...oldInfo.last, next].slice(-5);
        return nuInfo;
      });
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [pointerEvent$]);

  return showCursor ? (
    <div
      data-id={"SPOT"}
      style={{ position: "absolute", left: spot.x, top: spot.y }}
    >
      <svg>
        <circle cx={0} cy={0} r={30} fill={"orangered"} />
        <TextThing
          x={40}
          y={12}
          textBit={`down (${Math.round(spotInfo.downPoint.x)} , ${Math.round(
            spotInfo.downPoint.y
          )})`}
        />
        <TextThing
          x={40}
          y={28}
          textBit={`move (${Math.round(spotInfo.movePoint.x)} , ${Math.round(
            spotInfo.movePoint.y
          )})`}
        />
        <TextThing
          x={40}
          y={44}
          textBit={`up (${Math.round(spotInfo.upPoint.x)} , ${Math.round(
            spotInfo.upPoint.y
          )})`}
        />
      </svg>
    </div>
  ) : null;
};

export default DebugCursor;
