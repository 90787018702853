import { useEffect, useRef } from "react";
import { debounceTime, Observable } from "rxjs";
import { useRxState, useSubscribe } from "@ixd-group/react-utils";

import GestureExtractor from "../../../../services/gestureExtractor";
import { GestureData, PaneStage } from "../../types";
import { useModule } from "../..";

import Feedback from "./Feedback";
import Capture from "./Capture";
import { Container } from "./index.styles";

const PATH_TO_MODEL_JSON = "models/gestureModel08/model.json";

type Props = {
  completeCallback: () => void;
  draw?: boolean;
};

const Gesture: React.FC<Props> = ({ completeCallback, draw = true }) => {
  const {
    services: { paneManager },
    stores: { gesture$, headerVisible$ },
  } = useModule();
  const gestureCaptureRef = useRef<Observable<GestureData>>(null);
  const gesture = useRxState(gesture$);
  const headerVisible = useRxState(headerVisible$);

  useEffect(() => {
    const extractor = new GestureExtractor(PATH_TO_MODEL_JSON);
    const gestureData$ = gestureCaptureRef.current;
    if (gestureData$) {
      extractor.init(gestureData$).subscribe((gesture: string) => {
        gesture$.set(gesture);
      });
    }
  }, [gesture$]);

  useSubscribe(gesture$.pipe(debounceTime(500)), (gesture) => {
    switch (gesture) {
      case "palm":
        paneManager.openAndPlayVideo();
        break;
      case "pinch":
        paneManager.handlePaneBack(null);
        break;
      case "thumb down":
        paneManager.triggerReaction("thumbDown");
        break;
      case "thumb up":
        paneManager.triggerReaction("thumbUp");
        break;
    }
  });

  const paused = paneManager.findPane()?.stage === PaneStage.Paused;

  return (
    <Container>
      <Feedback
        isPlaying={!paused}
        gesture={gesture}
        showText={!headerVisible}
      />
      <Capture
        draw={draw}
        hands
        ref={gestureCaptureRef}
        completeCallback={completeCallback}
      />
    </Container>
  );
};

export default Gesture;
