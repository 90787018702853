import { ImageHolder, Layer, LayerWithShade } from "../Pane/index.styles";
import ContentTitle, { TitleCommand } from "../ContentTitle";
import { uiScale } from "../../constants";
import React, { FC } from "react";
import { PosterInfo } from "../../types";
import { Observable } from "rxjs";

export type Props = {
  visible: boolean;
  src: string;
  info: PosterInfo;
  command$: Observable<TitleCommand>;
};

const PosterLayer: FC<Props> = ({ visible, src, info, command$ }) => {
  return (
    <>
      {visible ? (
        <>
          <Layer>
            <ImageHolder src={src} draggable="false" />
          </Layer>
          <LayerWithShade />
          <Layer>
            <ContentTitle {...info} scale={uiScale} command$={command$} />
          </Layer>
        </>
      ) : null}
    </>
  );
};

export default PosterLayer;
