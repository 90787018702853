import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

import { GestureData } from "../../modules/launcher/types";
import NeuralNet from "./neuralNet";

export type Cursor = {
  left: { x: number; y: number } | null;
  right: { x: number; y: number } | null;
};

class GestureExtractor {
  private gestureZoom: number = 1;
  private landmarkIndex = 0;
  private outputHeight: number = 600;
  private outputWidth: number = 800;

  private nn: NeuralNet;
  private loaded: boolean = false;
  private dataSubscription: Subscription | undefined;
  private _cursor = new BehaviorSubject<Cursor>({ left: null, right: null });
  private _gesture = new BehaviorSubject<string>("nothing");

  get cursor() {
    return this._cursor;
  }

  get gesture() {
    return this._gesture.pipe(distinctUntilChanged());
  }

  constructor(pathToModelJson: string) {
    this.nn = new NeuralNet(pathToModelJson, () => {
      this.loaded = true;
    });
  }

  init(data: Observable<GestureData>): Observable<string> {
    this.dataSubscription = data.subscribe(this.processData.bind(this));
    return this.gesture;
  }

  destroy() {
    this.dataSubscription?.unsubscribe();
  }

  setDimensions(width?: number, height?: number) {
    this.outputWidth = width ?? this.outputWidth;
    this.outputHeight = height ?? this.outputHeight;
  }

  private calculateCursor(data: GestureData) {
    if (data.model === "hands") {
      const cursor = { left: null, right: null };

      ["Left", "Right"].forEach((hand) => {
        const multiHandLandmarks =
          data.results.multiHandLandmarks?.[
            data.results.multiHandedness?.findIndex(
              ({ label }) => label === hand
            )
          ];
        // @ts-ignore
        cursor[hand.toLowerCase()] = multiHandLandmarks?.[this.landmarkIndex]
          ? {
              x:
                this.outputWidth / 2 -
                this.gestureZoom *
                  ((multiHandLandmarks[this.landmarkIndex].x - 0.5) *
                    this.outputWidth),
              y:
                this.outputHeight / 2 +
                this.gestureZoom *
                  ((multiHandLandmarks[this.landmarkIndex].y - 0.5) *
                    this.outputHeight),
            }
          : null;
      });
      this._cursor.next(cursor);
    }
  }

  private processData(data: GestureData) {
    if (this.loaded) {
      this.nn.classify(data, (error, results) => {
        if (!error) {
          const { confidence, label } = results[0];
          this._gesture.next(confidence < 0.9 ? "nothing" : label);
        } else if (error === "No data") {
          this._gesture.next("no hands");
        }
      });
    }

    this.calculateCursor(data);
  }
}

export default GestureExtractor;
