import { FC } from "react";
import { motion } from "framer-motion";
import { Expression } from "../../types";

export type Props = {};

const icons = {
  pencil: {
    img: "assets/icons/noun-pencil-1778349-FFFFFF.svg",
    alt: "pencil",
  },
  heart: {
    img: "assets/icons/noun-classic-heart-outline-612687-FFFFFF.svg",
    alt: "heart",
  },
  thumbUp: {
    img: "assets/icons/noun-thumbs-up-996752-FFFFFF.svg",
    alt: "thumb up",
  },
  thumbDown: {
    img: "assets/icons/noun-thumbs-down-996748-FFFFFF.svg",
    alt: "thumb down",
  },
};

export type ButtonIcon = "pencil" | "heart" | "thumbUp" | "thumbDown";

type ButtonProps = {
  type: ButtonIcon;
  callback: (v: Expression) => void;
};

export const ReactionButton: FC<ButtonProps> = ({ type, callback }) => {
  function handleClick(e: any) {
    e.stopPropagation();
    callback(type);
  }

  return (
    <motion.button
      style={{
        width: 120,
        height: 120,
        backgroundColor: "rgba(255,255,255,0.3",
        border: "none",
        borderRadius: 16,
      }}
      whileTap={{ scale: 0.9 }}
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <img src={icons[type].img} alt={icons[type].alt} width={90} />
      </div>
    </motion.button>
  );
};
