import { FC } from "react";
import { ReactionIcons } from "../../constants";
import { ParticleMinimalData } from "./ParticleModel";

export type Props = {
  model: ParticleMinimalData;
};

export const SimpleParticle: FC<Props> = ({ model }) => {
  // @ts-ignore
  const imgSrc: any = ReactionIcons[model.icon].img;
  return (
    <div style={{ position: "absolute", top: model.y, left: model.x }}>
      <img src={imgSrc} width={90} height={90} alt={"icon"} />
    </div>
  );
};

export default SimpleParticle;
