import { observable, BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, publishReplay, refCount } from 'rxjs/operators';

function get(obj) {
    if ("getValue" in obj) {
        return obj.getValue();
    }
    else if ("get" in obj) {
        return obj.get();
    }
    else {
        let value;
        let callbackCalled = false;
        const subscription = obj.subscribe((v) => {
            value = v;
            callbackCalled = true;
        });
        subscription.unsubscribe();
        if (!callbackCalled) {
            throw new Error("Cannot get value from a stream that doesn't call its subscriber synchronously");
        }
        return value;
    }
}

var _a, _b;
const IS_ATOM = "@@IS_ATOM";
class ReadonlyAtom {
    constructor(source) {
        this[_a] = true;
        // @ts-ignore - haven't been able to work with args spread and pipe overloads yet
        this.pipe = (...args) => {
            // @ts-ignore
            return this.source.pipe(...args);
        };
        this.source = source.pipe(distinctUntilChanged());
    }
    [observable]() {
        return this.source;
    }
    get() {
        return get(this.source);
    }
    map(mapper) {
        return new ReadonlyAtom(this.pipe(map(mapper)));
    }
    subscribe(callback) {
        return this.source.subscribe(callback);
    }
}
_a = IS_ATOM;
class WritableAtom {
    constructor(bs) {
        this[_b] = true;
        // @ts-ignore - haven't been able to work with args spread and pipe overloads yet
        this.pipe = (...args) => {
            // @ts-ignore
            return this.source.pipe(...args);
        };
        this.bs = bs;
        this.source = bs.pipe(distinctUntilChanged());
    }
    [observable]() {
        return this.source;
    }
    get() {
        return this.bs.getValue();
    }
    set(value) {
        this.bs.next(value);
    }
    update(updater) {
        this.set(updater(this.get()));
    }
    map(mapper) {
        return new ReadonlyAtom(this.pipe(map(mapper)));
    }
    subscribe(callback) {
        return this.source.subscribe(callback);
    }
    readonly() {
        return new ReadonlyAtom(this.source);
    }
}
_b = IS_ATOM;
function atom(value) {
    return new WritableAtom(new BehaviorSubject(value));
}
function readonlyAtom(value) {
    const atm = atom(value);
    return [atm.readonly(), atm.set.bind(atm)];
}

const isAtom = (thing) => {
    return !!thing[IS_ATOM];
};

function arrayToLookup(keys, values) {
    const mem = {};
    return keys.reduce((memo, key, i) => {
        memo[key] = values[i];
        return memo;
    }, mem);
}
// Implementation
function combine(observables, mapper) {
    let stream$;
    if (Array.isArray(observables)) {
        stream$ = combineLatest(observables);
        if (mapper) {
            stream$ = stream$.pipe(map((values) => mapper(values)));
        }
    }
    else {
        const keys = Object.keys(observables);
        stream$ = combineLatest(keys.map((k) => observables[k])).pipe(map((values) => {
            const valuesLookup = arrayToLookup(keys, values);
            return mapper ? mapper(valuesLookup) : valuesLookup;
        }));
    }
    return new ReadonlyAtom(stream$.pipe(publishReplay(1), refCount(), distinctUntilChanged()));
}

export { ReadonlyAtom, WritableAtom, atom, combine, get, isAtom, readonlyAtom };
