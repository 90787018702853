import { FC, ReactComponentElement, useEffect, useRef, useState } from "react";
import { Point } from "../../types";
import { magnitudePt, subtractPts } from "../../utils";
import { Socket } from "socket.io-client";
import { isEqual } from "lodash";
import fitCurve from "fit-curve";

export type Props = {
  socket: Socket;
  uuid: string;
  enableLocalDrawing: boolean;
};

type LineDatum = Point & {
  time: number;
};

type LineData = LineDatum[];
type MultiLinesData = LineData[];

const drawLiveTime = 3000;
const minLineLength = 15;

function multiLineDataToLines(
  multiLineData: MultiLinesData,
  rootKey: string
): ReactComponentElement<any>[] {
  return multiLineData.reduce((prev, curr, idx) => {
    const nuElements = lineDataToSmoothLines(curr, `${rootKey}-${idx}-`);
    return [...prev, ...nuElements];
  }, [] as ReactComponentElement<any>[]);
}

function lineDataToLines(
  data: LineDatum[],
  keyRoot: string
): ReactComponentElement<any>[] {
  let lines = [];

  if (data.length > 1) {
    for (let i = 0; i < data.length - 1; i++) {
      const d1 = data[i];
      const d2 = data[i + 1];
      const l = (
        <line
          key={`${keyRoot}${d1.time}`}
          x1={d1.x}
          x2={d2.x}
          y1={d1.y}
          y2={d2.y}
          stroke={"white"}
          strokeWidth={4}
        />
      );
      lines.push(l);
    }
  } else {
    lines = [];
  }
  return lines;
}

function lineDataToSmoothLines(
  data: LineDatum[],
  keyRoot: string
): ReactComponentElement<any>[] {
  const pts = lineDataToPointArray(data);
  const curves = pts.map((stroke) => fitCurve(pts, 2));
  return bezierDataToString(curves).map((path, idx) =>
    pathToReactElement(keyRoot, idx, path)
  );
}

export function pathToReactElement(
  keyRoot: string,
  count: number,
  path: string
) {
  return (
    <path
      key={`${keyRoot}${count}`}
      d={path}
      stroke={"white"}
      strokeWidth={4}
      fill={"none"}
    />
  );
}

export function bezierDataToString(bezierData: number[][][][]): string[] {
  return bezierData.map((stroke) =>
    stroke.reduce((prev, c) => {
      return (
        prev +
        `M ${c[0][0]} ${c[0][1]} C ${c[1][0]} ${c[1][1]}, ${c[2][0]} ${c[2][1]}, ${c[3][0]} ${c[3][1]} `
      );
    }, "")
  );
}

function lineDataToPointArray(data: LineDatum[]): number[][] {
  return data.map((pt) => [pt.x, pt.y]);
}

export const DrawArea: FC<Props> = ({ socket, uuid, enableLocalDrawing }) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [lineData, setLineData] = useState<MultiLinesData>([]);
  const [remoteLines, setRemoteLines] = useState<Map<string, MultiLinesData>>(
    new Map()
  );

  useEffect(() => {
    let pointerDown = false;
    let lastPoint: Point = { x: 0, y: 0 };
    // let oldLineData = [];
    let oldMultiLineData: MultiLinesData = [];

    function mousePtToSvg(
      svg: SVGSVGElement | null,
      mousePt: { x: number; y: number }
    ) {
      if (!svg) return null;
      const pt = svg.createSVGPoint();
      pt.x = mousePt.x;
      pt.y = mousePt.y;
      const matrix = svg.getScreenCTM();
      if (matrix) {
        return pt.matrixTransform(matrix.inverse());
      }
      return null;
    }

    function handleTouchStart(e: TouchEvent) {
      const touches = e.changedTouches;
      const touch = touches[0];
      handlePointerDown({ x: touch.clientX, y: touch.clientY });
    }

    function handleMouseDown(e: MouseEvent) {
      handlePointerDown({ x: e.clientX, y: e.clientY });
    }

    function handleTouchMove(e: TouchEvent) {
      const touches = e.changedTouches;
      const touch = touches[0];
      handlePointerMove({ x: touch.clientX, y: touch.clientY });
    }

    function handleMove(e: MouseEvent) {
      handlePointerMove({ x: e.clientX, y: e.clientY });
    }

    function handleTouchEnd() {
      handlePointerUp();
    }

    function handleMouseUp() {
      handlePointerUp();
    }

    function handlePointerMove(pt: Point) {
      if (pointerDown) {
        const svgPt = mousePtToSvg(svgRef.current, pt);
        if (svgPt) {
          let pt = { x: svgPt.x, y: svgPt.y };
          let now = new Date().getTime();
          const d = { x: pt.x, y: pt.y, time: now };
          if (magnitudePt(subtractPts(d, lastPoint)) > minLineLength) {
            lastPoint = pt;
            setLineData((multiLine: MultiLinesData) => {
              const nuMultiLine: MultiLinesData = [];
              multiLine.forEach((data, idx) => {
                const nuData = data.filter((d) => d.time > now - drawLiveTime);
                if (idx === multiLine.length - 1) {
                  nuData.push(d);
                }
                if (nuData.length > 0) {
                  nuMultiLine.push(nuData);
                }
              });
              return nuMultiLine;
            });
          }
        }
      }
    }

    function handlePointerDown(pt: Point) {
      if (!enableLocalDrawing) return;
      const svgPt = mousePtToSvg(svgRef.current, pt);
      if (svgPt) {
        lastPoint = { x: svgPt.x, y: svgPt.y };
        let now = new Date().getTime();
        setLineData((oldMultiLines) => {
          return [
            ...oldMultiLines,
            [{ x: lastPoint.x, y: lastPoint.y, time: now }],
          ];
        });
      }
      pointerDown = true;
    }

    function handlePointerUp() {
      pointerDown = false;
    }

    function handleRemoteMultilineData(data: any) {
      setRemoteLines((oldLines) => {
        const newLines = new Map(oldLines);
        newLines.set(data.id, data.multilines);
        return newLines;
      });
    }

    window.addEventListener("mousemove", handleMove);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchend", handleTouchEnd);
    window.addEventListener("touchmove", handleTouchMove);

    socket.on("multiLineData", handleRemoteMultilineData);

    function loop() {
      const now = new Date().getTime();
      setLineData((multliLineData) => {
        const nuMultiLineData: MultiLinesData = [];
        multliLineData.forEach((data) => {
          const nuData = data.filter((d) => d.time > now - drawLiveTime);
          if (nuData.length > 0) {
            nuMultiLineData.push(nuData);
          }
        });
        if (!isEqual(nuMultiLineData, oldMultiLineData)) {
          oldMultiLineData = nuMultiLineData;
          socket.emit("BROADCAST", {
            event: "multiLineData",
            payload: { multilines: nuMultiLineData, id: uuid },
          });
        }
        return nuMultiLineData;
      });
      window.requestAnimationFrame(loop);
    }

    window.requestAnimationFrame(loop);

    return () => {
      window.removeEventListener("mousemove", handleMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchend", handleTouchEnd);
      window.removeEventListener("touchmove", handleTouchMove);
      socket.off("multiLineData", handleRemoteMultilineData);
    };
  }, [enableLocalDrawing, socket, svgRef, uuid]);

  const lines = multiLineDataToLines(lineData, "local");

  const lineKeys = Array.from(remoteLines.keys());
  let remoteLineElements: any[] = [];
  lineKeys.forEach((k) => {
    const data = remoteLines.get(k);
    if (data) {
      const paths = multiLineDataToLines(data, k);
      remoteLineElements = [...remoteLineElements, ...paths];
    }
  });

  return (
    <div style={{ width: "100%", height: "100%", position: "absolute" }}>
      <svg
        ref={svgRef}
        style={{ width: "100%", height: "100%" }}
        width={1920}
        height={1080}
      >
        {lines}
        {remoteLineElements}
      </svg>
    </div>
  );
};
