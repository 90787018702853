import { BehaviorSubject } from "rxjs";
import { Results as HandsResults } from "@mediapipe/hands";

import PaneDimensions from "./utils/PaneDimensions";

export enum PaneState {
  Open = "open",
  Shut = "shut",
}

export enum PaneStage {
  Preview = "preview",
  Playing = "playing",
  Poster = "poster",
  Paused = "paused",
  Episodes = "episodes",
}

export type PaneData = {
  videoInfo: VideoInfo;
  id: number;
  dimensions: PaneDimensions;
  command$: BehaviorSubject<PaneCommand>;
  state: PaneState;
  startWithPoster: boolean;
  xOffset: number;
  showBackButton: boolean;
};

export type EpisodeData = {
  id: string;
  dimensions: PaneDimensions;
  poster: string;
  titles: string;
  videoSrc: string;
  command$: BehaviorSubject<PaneCommand>;
  isApp: boolean;
};

export type PaneCommand =
  | {
      dimensions: PaneDimensions;
      command: "resize";
    }
  | { command: "none" }
  | { command: "showPoster" }
  | { command: "showVideo" }
  | { command: "open" }
  | { command: "shut" }
  | { command: "playVideo" }
  | { command: "pauseVideo" }
  | { command: "stopVideo" }
  | { command: "resumeVideo" }
  | { command: "notifyShowingEpisodes" }
  | { command: "triggerReaction"; expression: Expression }
  | { command: "enableDraw" }
  | { command: "disableDraw" }
  | { command: "showHighlight" }
  | { command: "hideHighlight" }
  | { command: "showVideoControls" }
  | { command: "hideVideoControls" }
  | { command: "skipForward" }
  | { command: "skipBack" };

export type GridCommand =
  | {
      command: "addMovie";
      videoInfo: VideoInfo;
    }
  | {
      command: "no opp";
    };

export type VideoInfo = {
  videoSrc: string;
  id: string;
  poster: PosterInfo;
  containsApps?: boolean;
};

export type PosterInfo = {
  posterSrc: string;
  line1: string;
  line2: string;
  titleArtSrc: string;
  providerLogo: string;
};

export type ReducedLauncherUpdate =
  | {
      panes: PaneData[];
      episodes: EpisodeData[];
      triggerAnimation: true;
      animationTotalFraction: number;
      durationSecs?: number;
      gridOffset: number;
      type: "full";
    }
  | {
      panes: PaneData[];
      episodes: EpisodeData[];
      triggerAnimation: false;
      gridOffset: number;
      type: "mini";
    };

export type LauncherUpdate =
  | ReducedLauncherUpdate
  | { lastClick: Point; type: "lastClick" }
  | { type: "revealTiles" }
  | { type: "hideTiles" }
  | { type: "showCursor"; value: boolean }
  | { type: "moveGrid"; pt: { x: number; y: number } }
  | { type: "tileVisibility"; value: boolean }
  | { type: "tweenGrid"; pt: { x: number; y: number }; callback: () => void };

export type SequenceStep = {
  duration: number;
  command: GridCommand;
};

export type Sequence = {
  label: string;
  steps: SequenceStep[];
};

export type PaneInteraction =
  | { action: "click"; id: number }
  | { action: "episodeClick"; id: string }
  | { action: "back"; id: number | null }
  | { action: "showEpisodes"; id: number }
  | { action: "pointDown"; id: number; pt: { x: number; y: number } }
  | { action: "pointMove"; id: number; pt: { x: number; y: number } }
  | { action: "pointUp"; id: number; pt: { x: number; y: number } }
  | { action: "globalPointerDown"; pt: { x: number; y: number }; id: "global" }
  | { action: "globalPointerUp"; pt: { x: number; y: number }; id: "global" }
  | { action: "globalPointerMove"; pt: { x: number; y: number }; id: "global" }
  | { action: "BackButtonActive"; active: boolean; id: number }
  | { action: "DrawingArea"; active: boolean; id: number };

export type EpisodeBlock = {
  id: string;
  episodes: {
    titles: string;
    poster: string;
    content: string;
    isApp?: boolean;
  }[];
};

export type PaneMode = "WATCH_TOGETHER_MODE" | "KITCHEN_MODE" | "BIKE_MODE";

export type Command =
  | {
      type: "CHANGE_MODE";
      payload: PaneMode;
    }
  | {
      type: "BACK";
    }
  | { type: "LAUNCH_CONTENT"; payload: string }
  | { type: "VOICE_CONTROL"; payload: string }
  | { type: "SHOW EPISODES"; payload: { paneColumn: number } }
  | { type: "TOGGLE TILES" }
  | { type: "TRIGGER_REACTION"; payload: Expression }
  | { type: "PLAY_OR_PAUSE" }
  | { type: "IDENTIFY" }
  | { type: "TOGGLE SHOW POINTER" };

export type ParticleInfo = {
  kind: Expression;
};

export type Expression = "heart" | "thumbUp" | "thumbDown" | "pencil";

export type Point = { x: number; y: number };
export type Rectangle = { x: number; y: number; width: number; height: number };

export type VideoCommand =
  | {
      action: "play";
    }
  | {
      action: "pause";
    }
  | { action: "mute" }
  | {
      action: "seek";
      location: number;
    }
  | { action: "back" };

export type VideoProgressInfo = {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
};

export type GridPointerEvent = {
  pt: Point;
  scaledPt: Point;
  id: string;
  type: "DOWN" | "MOVE" | "DRAG" | "UP";
};

export type ReactionHotspot = {
  rect: Rectangle;
  label: Expression;
};

export type ReactionButtonAction = "clickDown" | "clickUp";

export type ReactionUICommand = {
  button: Expression;
  action: ReactionButtonAction;
};

export type GestureData = {
  model: "hands";
  results: HandsResults;
};
