import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import type { BehaviorSubject } from "rxjs";
import { motion, useAnimation } from "framer-motion";

import {
  Expression,
  PaneCommand,
  PaneInteraction,
  ParticleInfo,
  PosterInfo,
  ReactionUICommand,
  VideoCommand,
  VideoProgressInfo,
} from "../../types";
import PaneDimensions from "../../utils/PaneDimensions";
import { Layer } from "./index.styles";
import { bufferTime, Subject, Subscription, tap } from "rxjs";
import { TitleCommand } from "../ContentTitle";
import { ReactionControls } from "../ReactionControls";
import ParticleManager from "../ParticleCommander/ParticleManager";
import VideoLayer from "../VideoLayer";
import PosterLayer from "../PosterLayer";
import { BackButton } from "../BackButton";
import { DrawArea } from "../DrawArea";
import { useModule } from "../../index";

export type Props = {
  command$: BehaviorSubject<PaneCommand>;
  id: number;
  videoSrc: string;
  poster: PosterInfo;
  dimensions: PaneDimensions;
  interaction$: Subject<PaneInteraction>;
  startWithPoster: boolean;
  showReactions: boolean;
  showBackButton: boolean;
};

export const Pane: FC<Props> = ({
  command$,
  interaction$,
  id,
  videoSrc,
  poster,
  dimensions,
  startWithPoster,
  showReactions,
}) => {
  const titleCommand$ = useMemo(() => new Subject<TitleCommand>(), []);
  const videoCommand$ = useMemo(() => new Subject<VideoCommand>(), []);
  const particleRequests = useMemo(() => new Subject<ParticleInfo>(), []);
  const timeCreated: number = useMemo(() => {
    return new Date().getTime();
  }, []);

  const controls = useAnimation();
  const posterControls = useAnimation();
  const reactionButtonControls = useAnimation();
  const highlightControl = useAnimation();

  const [isBackButtonVisible, setBackButtonVisible] = useState(false);
  const [videoControlsVisible, setVideoControlsVisible] = useState(false);
  const [isPosterVisible, setPosterVisible] =
    useState<boolean>(startWithPoster);
  const [isVideoVisible, setVideoVisible] = useState<boolean>(!startWithPoster);
  const [isDrawingEnabled, setDrawingEnabled] = useState<boolean>(false);

  const videoProgressStreamRef = useRef(new Subject<VideoProgressInfo>());
  const reactionButtonCommands = useRef(new Subject<ReactionUICommand>());
  const videoSecondsPlayedRef = useRef(0);
  const lastValidVideoSyncReceived = useRef(0);

  const { top, left, width, height } = dimensions;
  const {
    services: { socket, uuid },
    stores: { headerVisible$ },
  } = useModule();

  // Broadcast Video Sync
  useEffect(() => {
    let subscription: Subscription | null = null;
    const timeInterval = 1000;
    const waitForSyncTime = 2000;
    subscription = videoProgressStreamRef.current
      .pipe(
        tap((info) => {
          videoSecondsPlayedRef.current = info.playedSeconds;
        }),
        bufferTime(timeInterval)
      )
      .subscribe((data) => {
        if (showReactions) {
          const now = new Date().getTime();
          const diff = now - lastValidVideoSyncReceived.current;
          if (diff > waitForSyncTime && data.length > 0) {
            socket.emit("BROADCAST", {
              event: "videoSync",
              payload: {
                uuid,
                seconds: data[0].playedSeconds,
                timeStamp: timeCreated,
              },
            });
          }
        }
      });
    return () => {
      if (subscription !== null) {
        subscription.unsubscribe();
      }
    };
  }, [showReactions, socket, timeCreated, uuid]);

  // Sync to Video Sync
  useEffect(() => {
    function handleVideoSync(data: {
      uuid: string;
      seconds: number;
      timeStamp: number;
    }) {
      if (data.timeStamp < timeCreated) {
        lastValidVideoSyncReceived.current = new Date().getTime();
        const minimumVideoTimeDiff = 0.5;
        const advance = 1;
        const target = data.seconds + advance;
        const diff = Math.abs(target - videoSecondsPlayedRef.current);
        if (diff > minimumVideoTimeDiff) {
          const command: VideoCommand = {
            action: "seek",
            location: target,
          };
          console.log("seeking", data.seconds);
          videoCommand$.next(command);
        }
      }
    }
    if (showReactions) {
      socket.on("videoSync", handleVideoSync);
    }
    return () => {
      socket.off("videoSync", handleVideoSync);
    };
  }, [socket, showReactions, timeCreated, videoCommand$]);

  useEffect(() => {
    function initAnimation(
      left: number,
      top: number,
      width: number,
      height: number,
      duration: number = 1000
    ) {
      controls.start({
        width,
        height,
        left,
        top,
        transition: { duration: duration / 1000 },
      });
    }

    function setBackButtonStatus(active: boolean) {
      setBackButtonVisible(active);
      interaction$.next({
        id,
        active,
        action: "BackButtonActive",
      });
    }

    async function handleCommand(message: PaneCommand) {
      switch (message.command) {
        case "notifyShowingEpisodes":
          setBackButtonStatus(true);
          break;

        case "resize":
          const { top, left, width, height } = message.dimensions;
          initAnimation(left, top, width, height);
          break;

        case "showPoster":
          posterControls.stop();
          setPosterVisible(true);
          reactionButtonControls.start({
            opacity: 0,
            transition: { duration: 1, ease: "easeIn" },
          });
          await posterControls.start({
            opacity: 1,
            transition: { duration: 1, ease: "easeIn" },
          });
          setVideoVisible(false);
          break;

        case "showVideo":
          posterControls.stop();
          setVideoVisible(true);
          reactionButtonControls.start({
            opacity: 1,
            transition: { duration: 2, ease: "easeIn" },
          });
          await posterControls.start({
            opacity: 0,
            transition: { duration: 2, ease: "easeIn" },
          });
          setPosterVisible(false);
          break;

        case "shut":
          titleCommand$.next({ command: "shut" });
          setTimeout(() => {
            headerVisible$.set(true);
          }, 1200);
          setBackButtonStatus(false);
          break;

        case "open":
          titleCommand$.next({ command: "open" });
          headerVisible$.set(false);
          break;

        case "playVideo":
          titleCommand$.next({ command: "hide" });
          posterControls.stop();
          setVideoVisible(true);
          reactionButtonControls.start({
            opacity: 1,
            transition: { duration: 2, ease: "easeIn" },
          });
          await posterControls.start({
            opacity: 0,
            transition: { duration: 2, ease: "easeIn" },
          });
          setPosterVisible(false);
          setVideoControlsVisible(false);
          videoCommand$.next({ action: "play" });
          setBackButtonStatus(false);
          break;

        case "pauseVideo":
          setVideoControlsVisible(true);
          videoCommand$.next({ action: "pause" });
          setBackButtonStatus(true);
          break;

        case "resumeVideo":
          videoCommand$.next({ action: "play" });
          setBackButtonStatus(false);
          break;

        case "stopVideo":
          titleCommand$.next({ command: "revealLeft" });
          setVideoVisible(true);
          setPosterVisible(true);
          setBackButtonStatus(true);

          // Show Poster
          posterControls.stop();
          setPosterVisible(true);

          reactionButtonControls.start({
            opacity: 0,
            transition: { duration: 2, ease: "easeIn" },
          });
          await posterControls.start({
            opacity: 1,
            transition: { duration: 2, ease: "easeIn" },
          });
          setVideoVisible(false);
          break;

        case "skipForward":
          videoCommand$.next({
            action: "seek",
            location: videoSecondsPlayedRef.current + 30,
          });
          break;

        case "skipBack":
          videoCommand$.next({
            action: "seek",
            location: videoSecondsPlayedRef.current - 30,
          });
          break;

        case "triggerReaction":
          handleReactions(message.expression);
          break;

        case "enableDraw":
          // handleReactions("pencil", true);
          reactionButtonCommands.current.next({
            button: "pencil",
            action: "clickUp",
          });
          break;

        case "disableDraw":
          // handleReactions("pencil", false);
          reactionButtonCommands.current.next({
            button: "pencil",
            action: "clickUp",
          });
          break;

        case "showHighlight":
          highlightControl.stop();
          highlightControl.start({
            backgroundColor: "rgba(255,255,255,0.2)",
            transition: {
              duration: 0.05,
            },
          });
          break;

        case "hideHighlight":
          highlightControl.stop();
          highlightControl.start({
            backgroundColor: "rgba(255,255,255,0)",
            transition: {
              duration: 0.3,
            },
          });
          break;

        case "showVideoControls":
          setVideoControlsVisible(true);
          break;

        case "hideVideoControls":
          setVideoControlsVisible(false);
          break;
      }
    }
    const subscription = command$.subscribe(handleCommand);
    const lastMessage = command$.getValue();
    handleCommand(lastMessage);

    return () => {
      subscription.unsubscribe();
    };
  }, [
    command$,
    controls,
    posterControls,
    titleCommand$,
    reactionButtonControls,
    videoCommand$,
    id,
    interaction$,
  ]);

  function handleReactionDoNothing(v: Expression, value?: boolean) {
    // This does nothing because it's now handled via window touch events
    if (v === "pencil") {
      handleReactions(v, value);
    }
  }

  function handleReactions(v: Expression, value?: boolean) {
    if (v === "pencil") {
      if (value) {
        console.log("ENABLING DRAWING");
        setDrawingEnabled(true);
        interaction$.next({
          action: "DrawingArea",
          active: true,
          id,
        });
      } else {
        setDrawingEnabled(false);
        interaction$.next({
          action: "DrawingArea",
          active: false,
          id,
        });
      }
    } else {
      particleRequests.next({ kind: v });
    }
  }

  function handleVideoUpdates(data: VideoProgressInfo) {
    videoProgressStreamRef.current.next(data);
  }

  return (
    <motion.div
      animate={controls}
      style={{
        top,
        width,
        left,
        height,
        position: "absolute",
        overflow: "hidden",
      }}
    >
      <VideoLayer
        visible={isVideoVisible}
        src={videoSrc}
        command$={command$}
        interaction$={interaction$}
        instruction$={videoCommand$}
        showControls={videoControlsVisible}
        progressCallback={handleVideoUpdates}
      />
      <motion.div
        animate={posterControls}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          opacity: startWithPoster ? 1 : 0,
        }}
      >
        <PosterLayer
          visible={isPosterVisible}
          src={poster.posterSrc}
          info={poster}
          command$={titleCommand$}
        />
      </motion.div>

      {showReactions ? (
        <Layer>
          <ParticleManager
            particleRequest$={particleRequests}
            socket={socket}
            uuid={uuid}
          />
        </Layer>
      ) : null}

      {showReactions ? (
        <DrawArea
          socket={socket}
          uuid={uuid}
          enableLocalDrawing={isDrawingEnabled}
        />
      ) : null}

      {isBackButtonVisible && !videoControlsVisible ? <BackButton /> : null}

      <motion.div animate={reactionButtonControls} style={{ opacity: 0 }}>
        {showReactions ? (
          <ReactionControls
            callback={handleReactionDoNothing}
            commands={reactionButtonCommands.current}
          />
        ) : null}
      </motion.div>

      <motion.div
        animate={highlightControl}
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(255,255,255,0.0)",
          position: "relative",
        }}
      />
    </motion.div>
  );
};
