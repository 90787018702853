import { Container } from "./index.styles";
import React from "react";

export const BackButton = () => {
  function handleClick() {
    console.log("BACK");
  }
  return (
    <Container>
      <div onClick={handleClick}>
        <img src={"assets/ui/BackButton.png"} alt={"back button"} />
      </div>
    </Container>
  );
};
