import { Container, Container2 } from "./index.styles";
import { Observable } from "rxjs";
import {
  EpisodeData,
  GridCommand,
  PaneData,
  ReducedLauncherUpdate,
} from "../../types";
import { FC, useEffect, useState } from "react";
import { Pane } from "../Pane";
import {
  animate,
  AnimationPlaybackControls,
  motion,
  useAnimation,
  useMotionValue,
} from "framer-motion";
import { useModule } from "../../";
import { Episode } from "../Episode";
import DebugCursor from "./DebugCursor";
import DebugHotSpots from "./DebugHotSpots";

const showDebugHotspots = false;

export type Props = {
  command$: Observable<GridCommand>;
};

export const Grid: FC<Props> = ({ command$ }) => {
  const {
    services: { paneManager },
  } = useModule();
  const [paneData, setPaneData] = useState<PaneData[]>([]);
  const [episodes, setEpisodes] = useState<EpisodeData[]>([]);
  const [tilesVisible, setTilesVisible] = useState<boolean>(false);
  const [gridOffset, setGridOffset] = useState(0);
  const [showCursor, setShowCursor] = useState(false);

  const animationValue = useMotionValue(0);
  const tilesControl = useAnimation();

  useEffect(() => {
    let animationControls: AnimationPlaybackControls | null = null;

    function handleUpdate(update: ReducedLauncherUpdate): void {
      setPaneData(update.panes);
      setEpisodes(update.episodes);
      setGridOffset(update.gridOffset);

      if (update.triggerAnimation) {
        const duration: number = update.durationSecs ? update.durationSecs : 1;
        animationValue.set(0);
        animationControls = animate(
          animationValue,
          update.animationTotalFraction,
          {
            duration,
            onUpdate: paneManager.handleAnimation.bind(paneManager),
            onComplete: paneManager.handleAnimationComplete.bind(paneManager),
            onStop: paneManager.handleAnimationStop.bind(paneManager),
          }
        );
      }
    }

    const subscription02 = paneManager.update$.subscribe((update) => {
      switch (update.type) {
        case "tileVisibility":
          setTilesVisible(update.value);
          break;

        case "moveGrid":
          tilesControl.start({
            left: update.pt.x,
            top: update.pt.y,
            transition: { duration: 0 },
          });
          break;

        case "tweenGrid":
          tilesControl.start({
            left: update.pt.x,
            top: update.pt.y,
            transition: {
              duration: 0.3,
              ease: "easeOut",
              onComplete: update.callback,
            },
          });
          break;

        case "revealTiles":
          tilesControl.start({
            top: -1080,
            transition: { duration: 0.2, ease: "easeOut" },
          });
          setTilesVisible(true);
          break;

        case "hideTiles":
          tilesControl.start(
            {
              top: 0,
              transition: { duration: 0.2, ease: "easeOut" },
            },
            {
              onComplete: () => {
                setTilesVisible(false);
              },
            }
          );
          break;

        case "showCursor":
          setShowCursor(update.value);
          break;

        case "lastClick":
          // prevents last click being passed thru to handleUpdate();
          break;

        default:
          handleUpdate(update);
      }
    });

    const subscription01 = command$.subscribe((message) => {
      switch (message.command) {
        case "addMovie":
          paneManager.addMovieWithOpenningAnimation(message.videoInfo);
          break;
      }
    });

    function loop() {
      if (isLooping) {
        paneManager.updateTime(new Date().getTime());
        window.requestAnimationFrame(loop);
      }
    }

    let isLooping = true;
    loop();

    function handleMouseDown(e: MouseEvent) {
      paneManager.interaction$.next({
        action: "globalPointerDown",
        pt: { x: e.clientX, y: e.clientY },
        id: "global",
      });
    }

    function handleMouseUp(e: MouseEvent) {
      paneManager.interaction$.next({
        action: "globalPointerUp",
        pt: { x: e.clientX, y: e.clientY },
        id: "global",
      });
    }

    function handleMouseMove(e: MouseEvent) {
      paneManager.interaction$.next({
        action: "globalPointerMove",
        pt: { x: e.clientX, y: e.clientY },
        id: "global",
      });
    }

    function handleTouchStart(e: TouchEvent) {
      const touches = e.changedTouches;
      const touch = touches[0];
      paneManager.interaction$.next({
        action: "globalPointerDown",
        pt: { x: touch.clientX, y: touch.clientY },
        id: "global",
      });
    }

    function handleTouchEnd(e: TouchEvent) {
      const touches = e.changedTouches;
      const touch = touches[0];
      paneManager.interaction$.next({
        action: "globalPointerUp",
        pt: { x: touch.clientX, y: touch.clientY },
        id: "global",
      });
    }

    function handleTouchMove(e: TouchEvent) {
      const touches = e.changedTouches;
      const touch = touches[0];
      paneManager.interaction$.next({
        action: "globalPointerMove",
        pt: { x: touch.clientX, y: touch.clientY },
        id: "global",
      });
    }

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("touchstart", handleTouchStart);
    document.addEventListener("touchend", handleTouchEnd);
    document.addEventListener("touchmove", handleTouchMove);

    return () => {
      isLooping = false;
      subscription01.unsubscribe();
      subscription02.unsubscribe();
      if (animationControls) animationControls.stop();
    };
  }, [command$, animationValue, paneManager, tilesControl]);

  const paneElements = paneData.map((p) => (
    <Pane
      showReactions={p.videoInfo.id === "strictlyComeDancing"}
      key={p.id}
      command$={p.command$}
      videoSrc={p.videoInfo.videoSrc}
      poster={p.videoInfo.poster}
      id={p.id}
      dimensions={p.dimensions}
      startWithPoster={p.startWithPoster}
      interaction$={paneManager.interaction$}
      showBackButton={p.showBackButton}
    />
  ));

  const episodeElements = episodes.map((e) => (
    <Episode key={e.id} {...e} interaction$={paneManager.interaction$} />
  ));

  return (
    <Container left={0} top={0} data-id="GRID">
      {/* Tile UI Grid */}
      <Container2 left={0} top={0} visible={tilesVisible}>
        <img
          src={"assets/ui/TilesUI.png"}
          alt={"Tiles UI"}
          draggable={false}
          style={{ userSelect: "none" }}
        />
      </Container2>
      <motion.div
        animate={tilesControl}
        style={{ left: gridOffset, top: 0, position: "absolute" }}
      >
        {paneElements}
        {episodeElements}
        {showDebugHotspots ? <DebugHotSpots /> : null}
        <DebugCursor
          pointerEvent$={paneManager.pointerEvent$}
          showCursor={showCursor}
        />
      </motion.div>
    </Container>
  );
};
