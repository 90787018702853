export function getWindowSize() {
  const d = document,
    root = d.documentElement,
    body = d.body;
  const width = window.innerWidth || root.clientWidth || body.clientWidth,
    height = window.innerHeight || root.clientHeight || body.clientHeight;
  return {
    width,
    height,
  };
}
