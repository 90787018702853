import styled from "@emotion/styled";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  font-family: "Sky Text Regular", sans-serif;
  color: white;
  display: flex;
  font-weight: normal;
  flex-direction: column-reverse;
  justify-content: flex-start;
`;

export const ExtraContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Spacer = styled.div`
  width: 100%;
`;
