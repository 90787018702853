import styled from "@emotion/styled";

export const ImageHolder = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  user-select: none;
`;

export const Layer = (args: any) => {
  const { children, style, ...otherArgs } = args;
  return (
    <div
      style={{ position: "absolute", width: "100%", height: "100%", ...style }}
      {...otherArgs}
    >
      {children}
    </div>
  );
};

export const LayerWithShade = (args: any) => {
  const { children, style, ...otherArgs } = args;
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        background:
          "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 45%, rgba(0,0,0,0) 100%)",
        ...style,
      }}
      {...otherArgs}
    >
      {children}
    </div>
  );
};
