import { fromEvent, merge, Observable, Subject, Subscription } from "rxjs";

class KeyListener {
  _keyStream = new Subject<KeyboardEvent>();
  _keySubscription: Subscription | null = null;

  private static _instance: KeyListener | null = null;

  public static getInstance() {
    if (KeyListener._instance === null) {
      KeyListener._instance = new KeyListener();
    }
    return KeyListener._instance;
  }

  public init() {
    const keyDownStream: Observable<KeyboardEvent> = fromEvent(
      document,
      "keydown"
    ) as Observable<KeyboardEvent>;
    const keyUpStream: Observable<KeyboardEvent> = fromEvent(
      document,
      "keyup"
    ) as Observable<KeyboardEvent>;
    const fullStream = merge(keyDownStream, keyUpStream);
    if (this._keySubscription) {
      this._keySubscription.unsubscribe();
    }
    this._keySubscription = fullStream.subscribe((event) => {
      this._keyStream.next(event);
    });
  }

  public get keyStream(): Observable<KeyboardEvent> {
    return this._keyStream.pipe();
  }
}

export default KeyListener;
