import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Observable, Subject } from "rxjs";
import { useRxState, useSubscribe } from "@ixd-group/react-utils";

import Hands, { HandsOptions } from "../../../../../services/hands";
import type { GestureData } from "../../../types";
import { useModule } from "../../..";

export type GestureOptions = {
  completeCallback?: () => void;
  draw: boolean;
  hands?: HandsOptions | boolean;
};

const useGestureCaptureLogic = (
  ref: React.ForwardedRef<Observable<GestureData>>,
  { draw, hands }: GestureOptions
) => {
  const {
    stores: { gestureStatus$ },
  } = useModule();
  const gestureStatus = useRxState(gestureStatus$);

  const [devices, setDevices] = useState<string[]>([]);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const cameraRef = useRef<HTMLVideoElement>(null);
  let handsService = useRef<Hands>();

  const gestureData = useMemo(() => new Subject<GestureData>(), []);

  useImperativeHandle(ref, () => gestureData, [gestureData]);

  useEffect(() => {
    if (handsService.current) {
      handsService.current.draw = draw;
    }
  }, [draw]);

  useEffect(() => {
    cameraRef.current?.addEventListener("error", (err) => {
      console.log("Gesture: An Error Occurred\n", err);
    });

    cameraRef.current?.addEventListener("loadeddata", () => {
      gestureStatus$.update((gs) => [...gs, "Video Data Loaded"]);

      const fpsInterval = 1000 / 5;
      let then = Date.now();

      const onFrame = async () => {
        const now = Date.now();
        const elapsed = now - then;

        if (elapsed > fpsInterval) {
          then = now - (elapsed % fpsInterval);
          await handsService.current?.send();
        }
        window.requestAnimationFrame(onFrame);
      };
      onFrame();
    });

    if ("mediaDevices" in navigator) {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        setDevices(
          devices
            .filter(({ kind }) => kind === "videoinput")
            .map(
              ({ deviceId, label }) =>
                label.replace(/(\r\n|\n|\r)/gm, "") ?? deviceId
            )
        );

        navigator.mediaDevices
          .getUserMedia({
            video: true,
          })
          .then((stream) => {
            if (cameraRef.current) {
              cameraRef.current.srcObject = stream;
              gestureStatus$.update((gs) => [...gs, "Media Stream Assigned"]);
            }
          })
          .catch((err) => {
            console.log("Gesture: An Error Occurred\n", err);
          });
      });
    } else {
      console.log("Gesture: Media Devices API Unsupported");
    }
  }, []);

  useEffect(() => {
    const { current: canvas } = canvasRef;
    const { current: camera } = cameraRef;

    let frameInterval: NodeJS.Timer;

    const initializeGestures = async () => {
      await handsService.current?.destroy();

      if (canvas && camera) {
        gestureStatus$.update((gs) => [
          ...gs,
          "Gesture Initialization Started",
        ]);
        handsService.current = new Hands(canvas, camera, {
          ...(typeof hands === "boolean" ? {} : hands),
          onResults: (results) => {
            gestureData.next({ model: "hands", results });
            gestureStatus$.update((gs) => {
              return gs.includes("Initial Result Processed")
                ? gs
                : [...gs, "Initial Result Processed"];
            });
          },
        });

        handsService.current
          ?.initialize()
          .then(() => {
            gestureStatus$.update((gs) => [
              ...gs,
              "MediaPipe Hands Initialized",
            ]);
          })
          .catch((err) => console.log("Gesture: An Error Occurred\n", err));
      }
    };

    initializeGestures();

    return () => {
      if (frameInterval) clearInterval(frameInterval);
    };
  }, []);

  useSubscribe(gestureStatus$, (gestureStatus) => {
    console.log(gestureStatus[gestureStatus.length - 1]);
  });

  return {
    cameraRef,
    canvasRef,
    devices,
    gestureStatus,
  };
};

export default useGestureCaptureLogic;
