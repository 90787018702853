import { LauncherUpdate, Point } from "../types";
import { Subject } from "rxjs";

type DragMode = "none" | "horizontal" | "vertical";
type StartDragInfo = {
  pt: { x: number; y: number };
  mode: DragMode;
  episodes: boolean;
};

class GridDragManager {
  private _lastDrag: { x: number; y: number } = { x: 0, y: 0 };
  private _startOffset = 0;
  private _gridEpisodeOffsetStart: number = 0;
  private _gridXY = { x: 0, y: 0 };
  private _currentXY = { x: 0, y: 0 };

  // Shared with PaneManager
  private _gridEpisodeOffset = 0;
  private _dragNotClick = false;
  private _startDragInfo: null | StartDragInfo = null;
  private _paneRotation: number = 0;

  // Pane set from Pane Manager
  private readonly _update$: Subject<LauncherUpdate>;
  private readonly _triggerUpdate: () => void;
  private readonly _episodeWidth: number;

  constructor(
    update$: Subject<LauncherUpdate>,
    triggerUpdate: () => void,
    episodeWidth: number
  ) {
    this._update$ = update$;
    this._triggerUpdate = triggerUpdate;
    this._episodeWidth = episodeWidth;
  }

  public get paneRotation(): number {
    return this._paneRotation;
  }

  public get startDragInfo(): StartDragInfo | null {
    return this._startDragInfo;
  }

  public get gridEpisodeOffset(): number {
    return this._gridEpisodeOffset;
  }

  public set gridEpisodeOffset(value: number) {
    this._gridEpisodeOffset = value;
  }

  public get gridEpisodeOffsetStart(): number {
    return this._gridEpisodeOffsetStart;
  }

  public set gridEpisodeOffsetStart(value: number) {
    this._gridEpisodeOffsetStart = value;
  }

  public get dragNotClick(): boolean {
    return this._dragNotClick;
  }

  public set dragNotClick(value) {
    this._dragNotClick = value;
  }

  public handleStartDragEpisodes(pt: Point) {
    //
    this._startDragInfo = { pt, episodes: true, mode: "horizontal" };
    this._lastDrag = pt;
    this._startOffset = this._gridEpisodeOffset;
  }

  public handleStartDragGrid(pt: Point) {
    this._startDragInfo = { pt, episodes: false, mode: "none" };
  }

  public handleDragMainGrid(pt: Point): Point {
    if (this._startDragInfo !== null && !this._startDragInfo.episodes) {
      let diffY = pt.y - this._startDragInfo.pt.y;
      let diffX = pt.x - this._startDragInfo.pt.x;

      // console.log("DRAGGING grid", diffX, diffY);

      const moveThreshold = 30;

      if (this._startDragInfo.mode === "none") {
        if (Math.abs(diffX) >= Math.abs(diffY)) {
          diffY = 0;
        } else {
          diffX = 0;
        }
        if (Math.abs(diffX) > moveThreshold) {
          this._startDragInfo.mode = "horizontal";
        }
        if (Math.abs(diffY) > moveThreshold) {
          if (this.startDragInfo === null) return this._currentXY;
          /*
        if (this._gridXY.y === 0 && diffY > 0) {
          return;
        }
        if (this._gridXY.y < 0 && diffY < 0) {
          return;
        }*/
          this._startDragInfo.mode = "vertical";
          this.sendGridCommand({ type: "tileVisibility", value: true });
        }
      }

      if (this._startDragInfo.mode === "vertical") {
        this._currentXY = { x: this._gridXY.x, y: this._gridXY.y + diffY };
        /// console.log("NEW POS", this._currentXY.x, this._currentXY.y);
        this.sendGridCommand({ type: "moveGrid", pt: this._currentXY });
      }

      /*

      //TEMP DISABLE SIDEWAYS DRAGGING

      if (this._startDragInfo.mode === "horizontal") {
        this._currentXY = { x: this._gridXY.x + diffX, y: this._gridXY.y };
        this.sendGridCommand({ type: "moveGrid", pt: this._currentXY });
      }
       */
    }
    return this._currentXY;
  }

  public handleDragEpisodes(
    pt: { x: number; y: number },
    numberOfEpisodes: number
  ) {
    if (this._startDragInfo !== null) {
      const diff = pt.x - this._startDragInfo.pt.x;
      const threshold = 10;
      if (Math.abs(diff) > threshold) {
        this._dragNotClick = true;
      }
      if (this._dragNotClick) {
        const minOffset = Math.min(
          0,
          1920 - (1 + numberOfEpisodes) * this._episodeWidth
        );
        this._gridEpisodeOffset = Math.max(
          Math.min(0, this._startOffset + diff),
          minOffset
        );
        this._gridEpisodeOffsetStart = this._gridEpisodeOffset;
        this._triggerUpdate();
      }
    }
  }

  public stopDrag(): boolean {
    const draggingHappened = !(
      this._startDragInfo === null || this._startDragInfo.mode === "none"
    );
    this._startDragInfo = null;
    const oldXY = this._gridXY;
    this._gridXY = this._currentXY;
    if (draggingHappened) {
      this.cleanUpAfterDragging(oldXY, this._gridXY);
    }
    return draggingHappened;
  }

  public revealTiles() {
    this.sendGridCommand({ type: "revealTiles" });
    this._gridXY = { x: 0, y: -1080 };
  }

  public hideTiles() {
    this.sendGridCommand({ type: "hideTiles" });
    this._gridXY = { x: 0, y: 0 };
  }

  public cleanUpAfterDragging(oldPt: Point, newPt: Point) {
    const tilesDragThreshold = 200;
    const yDiff = newPt.y - oldPt.y;
    const xDiff = newPt.x - oldPt.x;
    if (yDiff !== 0) {
      if (yDiff < 0) {
        if (yDiff < -tilesDragThreshold) {
          this.revealTiles();
        } else {
          this.hideTiles();
        }
      } else {
        if (yDiff > tilesDragThreshold) {
          this.hideTiles();
        } else {
          this.revealTiles();
        }
      }
    }
    if (xDiff !== 0) {
      let roundingFunc = Math.ceil;
      if (xDiff < 0) {
        roundingFunc = Math.floor;
      }
      const nearestClick =
        this._episodeWidth * roundingFunc(newPt.x / this._episodeWidth);
      this.sendGridCommand({
        type: "tweenGrid",
        pt: { x: nearestClick, y: 0 },
        callback: () => {
          const click = (nearestClick / this._episodeWidth + 900) % 9;
        },
      });
    }
  }

  private sendGridCommand(p: LauncherUpdate) {
    this._update$.next(p);
  }
}

export default GridDragManager;
