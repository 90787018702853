class PaneDimensions {
  width: number = 0;
  height: number = 0;
  left: number = 0;
  top: number = 0;

  public static subtract(a: PaneDimensions, b: PaneDimensions): PaneDimensions {
    return {
      top: a.top - b.top,
      left: a.left - b.left,
      width: a.width - b.width,
      height: a.height - b.height,
    };
  }

  public static add(a: PaneDimensions, b: PaneDimensions): PaneDimensions {
    return {
      top: a.top + b.top,
      left: a.left + b.left,
      width: a.width + b.width,
      height: a.height + b.height,
    };
  }

  public static factor(v: number, p: PaneDimensions) {
    return {
      top: v * p.top,
      left: v * p.left,
      width: v * p.width,
      height: v * p.height,
    };
  }

}

export default PaneDimensions;
