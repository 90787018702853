import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { BehaviorSubject, Subject } from "rxjs";

import type {
  PaneCommand,
  PaneInteraction,
  VideoCommand,
  VideoProgressInfo,
} from "../../types";
import { uiHeight, uiWidth } from "../../constants";
import PlayerOverlay from "../PlayerOverlay";
import { Container } from "./index.styles";

export type Props = {
  command$?: BehaviorSubject<PaneCommand>;
  src: string;
  interaction$?: Subject<PaneInteraction>;
  instruction$: Subject<VideoCommand>;
  showControls: boolean;
  progressCallback?: (location: VideoProgressInfo) => void;
  visible: boolean;
};

const VideoLayer: React.FC<Props> = ({
  command$,
  visible,
  src,
  interaction$,
  instruction$,
  showControls,
  progressCallback,
}) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [progress, setProgress] = useState<VideoProgressInfo>();
  const [videoVolume, setVideoVolume] = useState(0);
  const playerRef = useRef<ReactPlayer>(null);

  function obtainRef(player: ReactPlayer) {
    // @ts-ignore
    playerRef.current = player;
  }

  useEffect(() => {
    const subscription = instruction$.subscribe((incoming) => {
      switch (incoming.action) {
        case "play":
          setIsPlaying(true);
          setVideoVolume(1);
          command$?.next({ command: "hideVideoControls" });
          break;
        case "pause":
          setIsPlaying(false);
          break;
        case "seek":
          playerRef.current?.seekTo(incoming.location, "seconds");
          command$?.next({ command: "hideVideoControls" });
          setIsPlaying(true);
          break;
        case "mute":
          setIsMuted((isMuted) => !isMuted);
          break;
        case "back":
          interaction$?.next({ action: "back", id: null });
          break;
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [instruction$]);

  function handleProgress(info: VideoProgressInfo) {
    setProgress(info);
    if (progressCallback) progressCallback(info);
  }

  return (
    <Container>
      {visible && (
        <div style={{ position: "absolute", bottom: 0 }}>
          <PlayerOverlay
            active={showControls}
            icon={isPlaying ? "play" : "pause"}
            instruction$={instruction$}
            muted={isMuted || videoVolume === 0}
            progress={progress}
            style={{
              height: uiHeight,
              opacity: showControls ? "1" : "0",
              transition: !showControls ? "opacity 0.5s 0.2s" : "none",
              width: uiWidth,
            }}
            title=""
          />
        </div>
      )}
      {visible ? (
        <ReactPlayer
          autoPlay
          playing={isPlaying}
          muted={isMuted || videoVolume === 0}
          volume={videoVolume}
          loop
          playsInline
          url={src}
          height={"100%"}
          width={"100%"}
          progressInterval={50}
          onProgress={handleProgress}
          ref={obtainRef}
        />
      ) : null}
    </Container>
  );
};

export default VideoLayer;
