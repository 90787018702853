import styled from "@emotion/styled";

export const Container = styled.div``;

export const Identifier = styled.div`
  background-color: black;
  color: white;
  font-size: 36pt;
  padding: 1.5rem 2rem;
  position: absolute;
  text-transform: uppercase;
  z-index: 1;
`;
