import { Clock } from "@ixd-group/common-stores";
import { atom, ReadonlyAtom } from "@ixd-group/rx-utils";

import { Props } from ".";

export const createStores = (moduleProps$: ReadonlyAtom<Props>) => {
  const clock = new Clock();
  return {
    clock,
    interactionsEnabled$: atom<boolean>(true),
    gesture$: atom<string | undefined>(undefined),
    headerVisible$: atom<boolean>(true),
    gestureStatus$: atom<string[]>([]),
  };
};
