import { FC, useEffect } from "react";
import { Observable } from "rxjs";
import { ControlsAnimationDefinition } from "framer-motion/types/animation/types";
import { motion, useAnimation } from "framer-motion";

export type Props = {
  animation$?: Observable<ControlsAnimationDefinition>;
  props?: any;
};

const MovingThing: FC<Props> = ({ props, animation$, children }) => {
  const controls = useAnimation();
  useEffect(() => {
    if (animation$) {
      const subscription = animation$.subscribe((next) => {
        controls.start(next);
      });
      return () => {
        controls.stop();
        subscription.unsubscribe();
      };
    }
  }, [animation$, controls]);
  return (
    <motion.div {...props} animate={controls}>
      {children}
    </motion.div>
  );
};

export default MovingThing;
