import PaneDimensions from "../../utils/PaneDimensions";

class MovingRectangle {
  private _delayFraction: number = 0;
  private _diff: PaneDimensions | null = null;
  private _dimensions: PaneDimensions;
  private _target: PaneDimensions | null = null;
  private _completeCallbacks: (() => void)[] = [];

  protected _k = 0;
  protected _current: PaneDimensions;

  constructor(dimensions: PaneDimensions) {
    this._dimensions = dimensions;
    this._current = dimensions;
  }

  public setTarget(
    target: PaneDimensions,
    delayFraction = 0,
    completeCallback?: () => void
  ) {
    this._target = target;
    this._delayFraction = delayFraction;
    this._diff = PaneDimensions.subtract(this._target, this._dimensions);
    if (completeCallback) this._completeCallbacks.push(completeCallback);
  }

  public setAnimationValue(v: number) {
    this._k = Math.min(1, Math.max(0, v - this._delayFraction));
    if (this._diff) {
      this._current = PaneDimensions.add(
        PaneDimensions.factor(this._k, this._diff),
        this._dimensions
      );
    }
  }

  public get current(): PaneDimensions {
    return this._current;
  }

  public set current(dimensions: PaneDimensions) {
    this._diff = null;
    this._target = null;
    this._current = dimensions;
    this._dimensions = dimensions;
  }

  public completeAnimation() {
    this.current = this._current;
    const todos = this._completeCallbacks;
    this._completeCallbacks = [];
    todos.forEach((cb) => {
      cb();
    });

  }
}

export default MovingRectangle;
