import PaneDimensions from "../../utils/PaneDimensions";
import { EpisodeData } from "../../types";
import MovingRectWithVideoContent from "./MovingRectWithVideoContent";

class EpisodeModel extends MovingRectWithVideoContent {
  private readonly _id: string;
  private readonly _poster: string;
  private readonly _titles: string;
  private readonly _videoSrc: string;
  private readonly _isApp:boolean;

  constructor(
    id: string,
    dimensions: PaneDimensions,
    titles: string,
    poster: string,
    videoSrc: string,
    timeStamp: number,
    isApp:boolean = false,
  ) {
    super(dimensions, timeStamp);
    this._titles = titles;
    this._poster = poster;
    this._id = id;
    this._videoSrc = videoSrc;
    this._isApp = isApp;
  }

  get info(): EpisodeData {
    return {
      titles: this._titles,
      poster: this._poster,
      dimensions: this.current,
      id: this._id,
      command$: this._command$,
      videoSrc: this._videoSrc,
      isApp:this._isApp,
    };
  }

  get id(): string {
    return this._id;
  }
}

export default EpisodeModel;
