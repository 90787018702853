import { VoiceListener } from "@ixd-group/common-services";

import { Command } from "../types";
import PaneManager from "./PaneManager";

class CommandInterpreter {
  _paneManager: PaneManager;
  _voiceListener: VoiceListener | null;

  constructor(paneManager: PaneManager, voiceListener: VoiceListener | null) {
    this._paneManager = paneManager;
    this._voiceListener = voiceListener;
  }

  dispatchCommand(command: Command) {
    switch (command.type) {
      case "CHANGE_MODE":
        switch (command.payload) {
          case "WATCH_TOGETHER_MODE":
            this._paneManager.changeMode("WATCH_TOGETHER_MODE");
            break;
          case "BIKE_MODE":
            this._paneManager.changeMode("BIKE_MODE");
            break;
          case "KITCHEN_MODE":
            this._paneManager.changeMode("KITCHEN_MODE");
            break;
        }
        break;
      case "BACK":
        this._paneManager.handlePaneBack(null);
        break;
      case "LAUNCH_CONTENT":
        this._paneManager.launchContent(command.payload);
        break;
      case "VOICE_CONTROL":
        if (this._voiceListener !== null) {
          this._voiceListener.wake(command.payload);
        }
        break;
      case "SHOW EPISODES":
        this._paneManager.showEpisodesForColumn(command.payload.paneColumn);
        break;
      case "TOGGLE TILES":
        this._paneManager.toggleTiles();
        break;
      case "TRIGGER_REACTION":
        this._paneManager.triggerReaction(command.payload);
        break;
      case "PLAY_OR_PAUSE":
        this._paneManager.openAndPlayVideo();
        break;

      case "TOGGLE SHOW POINTER":
        this._paneManager.handleToggleShowPointer();
        break;
    }
  }
}

export default CommandInterpreter;
