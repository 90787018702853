import styled from "@emotion/styled";

export const Container = styled.div`
  align-items: center;
  background-color: white;
  colour: black;
  display: flex;
  flex-direction: column;
  height: 1080px;
  justify-content: center;
  position: absolute;
  width: 1920px;

  p {
    font-size: 16pt;
    font-weight: 400;
  }
`;
